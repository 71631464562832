import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';
import {isEmpty, isWx} from "@/utils/utils";
import Layout from '@/layout';
import ResumePositionCategoryAdd from "@/views/resume/ResumePositionCategoryAdd";
const Home = ()=> import(/* webpackPrefetch: true, webpackChunkName: "Home" */ '@/views/Home');
const Login = ()=> import(/* webpackPrefetch: true, webpackChunkName: "Login" */ '@/views/login/Login');
const EnterpriseLogin = ()=> import(/* webpackPrefetch: true, webpackChunkName: "EnterpriseLogin" */ "@/views/login/EnterpriseLogin");
const EnterpriseRegister = ()=>import(/* webpackChunkName: "EnterpriseRegister" */ "@/views/login/EnterpriseRegister");
const CustomerLogin = ()=> import(/* webpackPrefetch: true, webpackChunkName: "CustomerLogin" */ "@/views/login/CustomerLogin");
const CustomerRegister = ()=> import(/* webpackChunkName: "CustomerRegister" */ "@/views/login/CustomerRegister");
const ForgotPassword = ()=> import(/* webpackChunkName: "ForgotPassword" */ "@/views/login/ForgotPassword");
const ProfileIndex = ()=> import(/* webpackChunkName: "ProfileIndex" */ "@/views/profile/ProfileIndex");
const PositionList = ()=> import(/* webpackPrefetch: true, webpackChunkName: "PositionList" */ "@/views/position/PositionList");
const PositionDetails = ()=> import(/* webpackChunkName: "PositionDetails" */ "@/views/position/PositionDetails");
const ResumeList = ()=> import(/* webpackPrefetch: true, webpackChunkName: "ResumeList" */ "@/views/resume/ResumeList");
const ResumeDetails = ()=> import(/* webpackChunkName: "ResumeDetails" */ "@/views/resume/ResumeDetails");
const RecruitDetails = ()=> import(/* webpackChunkName: "RecruitDetails" */ "@/views/recruit/RecruitDetails");
const ProductList = ()=> import(/* webpackChunkName: "ProductList" */ "@/views/product/ProductList");
const ProductDetails = ()=> import(/* webpackChunkName: "ProductDetails" */ "@/views/product/ProductDetails");
const MessageList = ()=> import(/* webpackChunkName: "MessageList" */ "@/views/message/MessageList");
const InterviewDetails = ()=> import(/* webpackChunkName: "InterviewDetails" */ "@/views/message/InterviewDetails");
const MyInfo = ()=> import(/* webpackChunkName: "MyInfo" */ "@/views/profile/MyInfo");
const ImproveMyInfo = ()=> import(/* webpackChunkName: "MyInfo" */ "@/views/profile/ImproveMyInfo");
const MyResume = ()=> import(/* webpackChunkName: "MyResume" */ "@/views/profile/MyResume");
const ImproveMyResume = ()=> import(/* webpackChunkName: "MyResume" */ "@/views/profile/ImproveMyResume");
const NewsList = ()=> import(/* webpackPrefetch: true, webpackChunkName: "NewsList" */ "@/views/news/NewsList");
const NewsDetails = ()=> import(/* webpackChunkName: "NewsDetails" */ "@/views/news/NewsDetails");
const CompanyList = ()=> import(/* webpackChunkName: "CompanyList" */ "@/views/company/CompanyList");
const CompanyDetails = ()=> import(/* webpackChunkName: "CompanyDetails" */ "@/views/company/CompanyDetails");
const ApplyMentor = ()=> import(/* webpackChunkName: "ApplyMentor" */ "@/views/profile/ApplyMentor");
const ResumeWorkExperienceAdd = ()=> import(/* webpackChunkName: "ResumeWorkExperienceAdd" */ "@/views/resume/ResumeWorkExperienceAdd");
const ResumeWorkExperienceEdit = ()=> import(/* webpackChunkName: "ResumeWorkExperienceEdit" */ "@/views/resume/ResumeWorkExperienceEdit");
const ResumeEducationAdd = ()=> import(/* webpackChunkName: "ResumeEducationAdd" */ "@/views/resume/ResumeEducationAdd");
const ResumeEducationEdit = ()=> import(/* webpackChunkName: "ResumeEducationEdit" */ "@/views/resume/ResumeEducationEdit");
const ResumeProficiencyLangEdit = ()=> import(/* webpackChunkName: "ResumeSkillEdit" */ "@/views/resume/ResumeProficiencyLangEdit");
const ResumeSkillEdit = ()=> import(/* webpackChunkName: "ResumeSkillEdit" */ "@/views/resume/ResumeSkillEdit");
const ResumePositionCategoryEdit = ()=> import(/* webpackChunkName: "ResumePositionCategoryEdit" */ "@/views/resume/ResumePositionCategoryEdit");
const MyFavorites = ()=> import(/* webpackChunkName: "MyFavorites" */ "@/views/profile/MyFavorites");
const MyApplicationRecord = ()=> import(/* webpackChunkName: "MyApplicationRecord" */ "@/views/profile/MyApplicationRecord");
const SendInterviewDetails = () => import(/* webpackChunkName: "SendInterviewDetails" */ "@/views/resume/SendInterviewDetails");
const ResumePersonalSummaryEdit = () => import(/* webpackChunkName: "ResumePersonalSummaryEdit" */ "@/views/resume/ResumePersonalSummaryEdit");
const TermsConditionsForJobSeeker = () => import(/* webpackChunkName: "ResumePersonalSummaryEdit" */ "@/views/agreement/TermsConditionsForJobSeeker");
const TermsConditionsForEmployer = () => import(/* webpackChunkName: "ResumePersonalSummaryEdit" */ "@/views/agreement/TermsConditionsForEmployer");
const PrivacyPolicy = () => import(/* webpackChunkName: "ResumePersonalSummaryEdit" */ "@/views/agreement/PrivacyPolicy");
const ContactUs = () => import(/* webpackChunkName: "ContactUs" */ '../views/about/ContactUs.vue')
const EmployerBranding = () => import(/* webpackChunkName: "EmployerBranding" */ "@/views/about/EmployerBranding");
const CareerGuidanceList = () => import(/* webpackChunkName: "CareerGuidanceList" */ "@/views/careerGuidance/CareerGuidanceList");
const CareerGuidanceDetails = () => import(/* webpackChunkName: "CareerGuidanceDetails" */ "@/views/careerGuidance/CareerGuidanceDetails");
const MyCertificateList = () => import(/* webpackChunkName: "MyCertificateList" */ "@/views/profile/myCertificate/MyCertificateList");
const CertificateAdd = () => import(/* webpackChunkName: "CertificateAdd" */ "@/views/profile/myCertificate/CertificateAdd");
const CertificateEdit = () => import(/* webpackChunkName: "CertificateEdit" */ "@/views/profile/myCertificate/CertificateEdit");
const MyTrain = () => import(/* webpackChunkName: "MyTrain" */ "@/views/profile/MyTrain");
const TrainIndex = () => import(/* webpackChunkName: "TrainIndex" */ "@/views/train/TrainIndex");
const OpenClassList = () => import(/* webpackChunkName: "OpenClassList" */ "@/views/train/OpenClassList");
const MyOrderList = () => import(/* webpackChunkName: "MyOrderList" */ "@/views/profile/myOrder/MyOrderList");
const InternalTrainingPage = () => import(/* webpackChunkName: "InternalTrainingPage" */ "@/views/train/InternalTrainingPage");
const EvaluationToolsPage = () => import(/* webpackChunkName: "InternalTrainingPage" */ "@/views/train/EvaluationToolsPage");
const ExchangeProgram = () => import(/* webpackChunkName: "InternalTrainingPage" */ "@/views/news/ExchangeProgram.vue");

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    meta: {
      title: "首页",
      auth: false,
      link: '/home',
      keepAlive: false
    },
    children: [{
      path: 'home',
      name: 'Home',
      meta: {
        title: '首页',
        auth: false,
        link: '/home',
        keepAlive: false
      },
      menu: 'home',
      component: Home
    },{
      path: 'profileIndex',
      name: 'ProfileIndex',
      meta: {
        title: '个人中心',
        auth: true,
        link: '/profileIndex',
        keepAlive: false
      },
      menu: 'profileIndex',
      component: ProfileIndex
    }, {
      path: 'resumeList',
      name: 'ResumeList',
      meta: {
        title: '求职者列表',
        auth: false,
        link: '/resumeList',
        keepAlive: true,
        scrollTop: 0
      },
      menu: 'resumeList',
      component: ResumeList
    },{
      path: 'positionList',
      name: 'PositionList',
      meta: {
        title: '职位列表',
        auth: false,
        link: '/positionList',
        keepAlive: true,
        scrollTop: 0
      },
      menu: 'positionList',
      component: PositionList
    },{
      path: 'positionDetails/:id',
      name: 'PositionDetails',
      meta: {
        title: '职位详情',
        auth: false,
        link: '/positionDetails',
        keepAlive: false
      },
      menu: 'positionDetails',
      component: PositionDetails
    },{
      path: 'trainIndex',
      name: 'TrainIndex',
      meta: {
        title: '培训',
        auth: false,
        link: '/trainIndex',
        keepAlive: false
      },
      menu: 'trainIndex',
      component: TrainIndex
    }]
  },
  {
    path: '/companyList',
    name: 'CompanyList',
    meta: {
      title: '公司列表',
      auth: false,
      link: '/companyList',
      keepAlive: true
    },
    menu: 'companyList',
    component: CompanyList
  },
  {
    path: '/companyDetails/:id',
    name: 'CompanyDetails',
    meta: {
      title: '公司详情',
      auth: false,
      link: '/companyDetails',
      keepAlive: false
    },
    menu: 'companyDetails',
    component: CompanyDetails
  },
  {
    path: '/newsList/:mainCategoryId?/:categoryId?',
    name: 'NewsList',
    meta: {
      title: '最新资讯',
      auth: false,
      link: '/newsList',
      keepAlive: false
    },
    menu: 'newsList',
    component: NewsList
  },
  {
    path: '/newsDetails/:id',
    name: 'NewsDetails',
    meta: {
      title: '资讯详情',
      auth: false,
      link: '/newsDetails',
      keepAlive: false
    },
    menu: 'newsDetails',
    component: NewsDetails
  },
  {
    path: '/resumeDetails/:id',
    name: 'ResumeDetails',
    meta: {
      title: '求职者详情',
      auth: false,
      link: '/resumeDetails',
      keepAlive: false
    },
    menu: 'resumeDetails',
    component: ResumeDetails
  },
  {
    path: '/recruitDetails',
    name: 'RecruitDetails',
    meta: {
      title: '招聘会',
      auth: false,
      link: '/recruitDetails',
      keepAlive: false
    },
    menu: 'recruitDetails',
    component: RecruitDetails
  },
  {
    path: '/productList',
    name: 'ProductList',
    meta: {
      title: '付费项目列表',
      auth: true,
      link: '/productList',
      keepAlive: false
    },
    menu: 'productList',
    component: ProductList
  },
  {
    path: '/productDetails/:id',
    name: 'ProductDetails',
    meta: {
      title: '付费项目详情',
      auth: true,
      link: '/productDetails',
      keepAlive: false
    },
    menu: 'productDetails',
    component: ProductDetails
  },
  {
    path: '/messageList',
    name: 'MessageList',
    meta: {
      title: '消息',
      auth: true,
      link: '/messageList',
      keepAlive: false
    },
    menu: 'messageList',
    component: MessageList
  },
  {
    path: '/interviewDetails/:id',
    name: 'InterviewDetails',
    meta: {
      title: '消息',
      auth: true,
      link: '/interviewDetails',
      keepAlive: false
    },
    menu: 'interviewDetails',
    component: InterviewDetails
  },
  {
    path: '/myInfo',
    name: 'MyInfo',
    meta: {
      title: '个人信息',
      auth: true,
      link: '/myInfo',
      keepAlive: false
    },
    menu: 'myInfo',
    component: MyInfo
  },{
    path: '/improveMyInfo',
    name: 'ImproveMyInfo',
    meta: {
      title: '完善个人信息',
      auth: true,
      link: '/improveMyInfo',
      keepAlive: false
    },
    menu: 'improveMyInfo',
    component: ImproveMyInfo
  },{
    path: '/myResume',
    name: 'MyResume',
    meta: {
      title: '我的简历',
      auth: true,
      link: '/myResume',
      keepAlive: false
    },
    menu: 'myResume',
    component: MyResume
  },{
    path: '/improveMyResume',
    name: 'ImproveMyResume',
    meta: {
      title: '完善我的简历',
      auth: true,
      link: '/improveMyResume',
      keepAlive: false
    },
    menu: 'improveMyResume',
    component: ImproveMyResume
  },{
    path: '/applyMentor',
    name: 'ApplyMentor',
    meta: {
      title: '申请职业导师',
      auth: true,
      link: '/applyMentor',
      keepAlive: false
    },
    menu: 'applyMentor',
    component: ApplyMentor
  },
  {
    path: '/resumeWorkExperienceAdd',
    name: 'ResumeWorkExperienceAdd',
    meta: {
      title: '添加工作经历',
      auth: true,
      link: '/resumeWorkExperienceAdd',
      keepAlive: false
    },
    menu: 'resumeWorkExperienceAdd',
    component: ResumeWorkExperienceAdd
  },
  {
    path: '/resumeWorkExperienceEdit/:id',
    name: 'ResumeWorkExperienceEdit',
    meta: {
      title: '修改教育经历',
      auth: true,
      link: '/resumeWorkExperienceEdit',
      keepAlive: false
    },
    menu: 'resumeWorkExperienceEdit',
    component: ResumeWorkExperienceEdit
  },
  {
    path: '/resumeEducationAdd',
    name: 'ResumeEducationAdd',
    meta: {
      title: '添加教育经历',
      auth: true,
      link: '/resumeEducationAdd',
      keepAlive: false
    },
    menu: 'resumeEducationAdd',
    component: ResumeEducationAdd
  },
  {
    path: '/resumeProficiencyLangEdit',
    name: 'ResumeProficiencyLangEdit',
    meta: {
      title: '语言技能',
      auth: true,
      link: '/resumeProficiencyLangEdit',
      keepAlive: false
    },
    menu: 'resumeProficiencyLangEdit',
    component: ResumeProficiencyLangEdit
  },
  {
    path: '/resumeSkillEdit',
    name: 'ResumeSkillEdit',
    meta: {
      title: '编辑职业技能',
      auth: true,
      link: '/resumeSkillEdit',
      keepAlive: false
    },
    menu: 'resumeSkillEdit',
    component: ResumeSkillEdit
  },
  {
    path: '/resumePositionCategoryEdit',
    name: 'ResumePositionCategoryEdit',
    meta: {
      title: '求职意向',
      auth: true,
      link: '/resumePositionCategoryEdit',
      keepAlive: false
    },
    menu: 'resumePositionCategoryEdit',
    component: ResumePositionCategoryEdit
  },
  {
    path: '/resumePositionCategoryAdd',
    name: 'ResumePositionCategoryAdd',
    meta: {
      title: '求职意向',
      auth: true,
      link: '/resumePositionCategoryAdd',
      keepAlive: false
    },
    menu: 'resumePositionCategoryAdd',
    component: ResumePositionCategoryAdd
  },
  {
    path: '/resumePersonalSummaryEdit',
    name: 'ResumePersonalSummaryEdit',
    meta: {
      title: '职业优势',
      auth: true,
      link: '/resumePersonalSummaryEdit',
      keepAlive: false
    },
    menu: 'resumePersonalSummaryEdit',
    component: ResumePersonalSummaryEdit
  },
  {
    path: '/resumeEducationEdit:id',
    name: 'ResumeEducationEdit',
    meta: {
      title: '修改教育经历',
      auth: true,
      link: '/resumeEducationEdit',
      keepAlive: false
    },
    menu: 'resumeEducationEdit',
    component: ResumeEducationEdit
  },{
    path: '/myFavorites',
    name: 'MyFavorites',
    meta: {
      title: '我的收藏',
      auth: true,
      link: '/myFavorites',
      keepAlive: false
    },
    menu: 'myFavorites',
    component: MyFavorites
  },{
    path: '/myApplicationRecord',
    name: 'MyApplicationRecord',
    meta: {
      title: '申请记录',
      auth: true,
      link: '/myApplicationRecord',
      keepAlive: false
    },
    menu: 'myApplicationRecord',
    component: MyApplicationRecord
  },{
    path: '/sendInterviewDetails/:applicantId/:positionId/:resumeDetailsId',
    name: 'SendInterviewDetails',
    meta: {
      title: '面试邀请',
      auth: true,
      link: '/sendInterviewDetails',
      keepAlive: false
    },
    menu: 'sendInterviewDetails',
    component: SendInterviewDetails
  },{
    path: '/login',
    name: 'Login',
    meta: {
      title: "欢迎登录/注册 DE德企招聘平台",
      auth: false,
      link: '/login',
      keepAlive: false
    },
    component: Login
  },
  {
    path: '/enterpriseLogin',
    name: 'EnterpriseLogin',
    meta: {
      title: "欢迎登录/注册 DE德企招聘平台",
      auth: false,
      link: '/enterpriseLogin',
      keepAlive: false
    },
    component: EnterpriseLogin
  },
  {
    path: '/enterpriseRegister',
    name: 'EnterpriseRegister',
    meta: {
      title: "企业注册",
      auth: false,
      link: '/enterpriseRegister',
      keepAlive: false
    },
    component: EnterpriseRegister
  },
  {
    path: '/customerLogin',
    name: 'CustomerLogin',
    meta: {
      title: "欢迎登录/注册 DE德企招聘平台",
      auth: false,
      link: '/customerLogin',
      keepAlive: false
    },
    component: CustomerLogin
  },
  {
    path: '/customerRegister',
    name: 'CustomerRegister',
    meta: {
      title: "用户注册",
      auth: false,
      link: '/customerRegister',
      keepAlive: false
    },
    component: CustomerRegister
  },
  {
    path: '/forgotPassword/:userType',
    name: 'ForgotPassword',
    meta: {
      title: "重置密码",
      auth: false,
      link: '/forgotPassword',
      keepAlive: false
    },
    component: ForgotPassword
  },
  {
    path: '/termsConditionsForJobSeeker',
    name: 'TermsConditionsForJobSeeker',
    meta: {
      title: "条款和条件(求职者)",
      auth: false,
      link: '/termsConditionsForJobSeeker',
      keepAlive: false
    },
    component: TermsConditionsForJobSeeker
  },
  {
    path: '/termsConditionsForEmployer',
    name: 'TermsConditionsForEmployer',
    meta: {
      title: "条款和条件(企业)",
      auth: false,
      link: '/termsConditionsForEmployer',
      keepAlive: false
    },
    component: TermsConditionsForEmployer
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    meta: {
      title: "隐私政策",
      auth: false,
      link: '/privacyPolicy',
      keepAlive: false
    },
    component: PrivacyPolicy
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/employerBranding',
    name: 'EmployerBranding',
    meta: {
      title: "雇主品牌推广",
      auth: false,
      link: '/employerBranding',
      keepAlive: false
    },
    component: EmployerBranding
  },
  {
    path: '/careerGuidanceList',
    name: 'CareerGuidanceList',
    meta: {
      title: "职涯辅导",
      auth: false,
      link: '/careerGuidanceList',
      keepAlive: false
    },
    component: CareerGuidanceList
  },
  {
    path: '/careerGuidanceDetails/:id',
    name: 'CareerGuidanceDetails',
    meta: {
      title: "职涯辅导详情",
      auth: false,
      link: '/careerGuidanceDetails',
      keepAlive: false
    },
    component: CareerGuidanceDetails
  },
  {
    path: '/myCertificateList',
    name: 'MyCertificateList',
    meta: {
      title: "我的证书",
      auth: false,
      link: '/myCertificateList',
      keepAlive: false
    },
    component: MyCertificateList
  },
  {
    path: '/certificateAdd',
    name: 'CertificateAdd',
    meta: {
      title: "添加证书",
      auth: false,
      link: '/certificateAdd',
      keepAlive: false
    },
    component: CertificateAdd
  },
  {
    path: '/certificateEdit',
    name: 'CertificateEdit',
    meta: {
      title: "编辑证书",
      auth: false,
      link: '/certificateEdit',
      keepAlive: false
    },
    component: CertificateEdit
  },
  {
    path: '/myTrain',
    name: 'MyTrain',
    meta: {
      title: "我的培训",
      auth: false,
      link: '/myTrain',
      keepAlive: false
    },
    component: MyTrain
  },
  {
    path: '/openClassList',
    name: 'OpenClassList',
    meta: {
      title: "公开课",
      auth: false,
      link: '/openClassList',
      keepAlive: false
    },
    component: OpenClassList
  },
  {
    path: '/myOrderList',
    name: 'MyOrderList',
    meta: {
      title: "我的订单",
      auth: false,
      link: '/myOrderList',
      keepAlive: false
    },
    component: MyOrderList
  },
  {
    path: '/internalTrainingPage',
    name: 'InternalTrainingPage',
    meta: {
      title: "内训/项目",
      auth: false,
      link: '/internalTrainingPage',
      keepAlive: false
    },
    component: InternalTrainingPage
  },
  {
    path: '/evaluationToolsPage',
    name: 'EvaluationToolsPage',
    meta: {
      title: "测评工具",
      auth: false,
      link: '/evaluationToolsPage',
      keepAlive: false
    },
    component: EvaluationToolsPage
  },
  {
    path: '/exchangeprogram',
    name: 'ExchangeProgram',
    meta: {
      title: "中德青年实习交流计划",
      auth: false,
      link: '/exchangeprogram',
      keepAlive: false
    },
    component: ExchangeProgram
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to,from, next)=> {
  isWx().then(type => {
    store.commit("SET_MINI_APP", type === "mini-wx");
    store.commit('SET_FORM_PAGE_PATH', from.fullPath);
    const token = store.state.user.userToken || to.query.token;
    if (from.meta.keepAlive) {
      const $content = document.querySelector('.jobs-content-inner'); // 列表的外层容器
      const scrollTop = $content ? $content.scrollTop : 0;
      //职位列表
      if(from.name === "PositionList") {
        localStorage.setItem('POSITION_LIST_SCROLL_TOP', scrollTop)
      }
      //求职者列表
      if(from.name === "ResumeList") {
        localStorage.setItem('RESUME_LIST_SCROLL_TOP', scrollTop)
      }
      //公司列表
      if(from.name === "CompanyList") {
        localStorage.setItem('COMPANY_LIST_SCROLL_TOP', scrollTop)
      }
    }

    //分享处理首页
    if(store.state.system.isMiniApp) {
      let url = `${window.location.origin}${process.env.VUE_APP_BASE_URL}`;
      let imageUrl = `https://ahk-oss-s.oss-cn-hangzhou.aliyuncs.com/ahk/Static/Images/share_cover.png`;
      window.wx.miniProgram.postMessage({
        data: {
          link: url,
          title: 'DE Talents',
          imageUrl: imageUrl
        }
      });
    }
    if(to.meta.auth && isEmpty(token)) {
      if(store.state.system.isMiniApp) {
        //微信小程序
        let langCode = localStorage.getItem('language');
        window.wx.miniProgram.redirectTo({url:`/pages/login/login?lang=${langCode}`});
      } else {
        if (to.path === '/customerLogin') { //跳出循环
          next();
        } else {
          next({path: '/customerLogin', query: {redirect: to.fullPath}})
        }
      }
    } else {
      if(store.state.system.isMiniApp) {
        if(!isEmpty(to.query)) {
          const queryData = to.query;
          if(queryData.loginStatus !== 204) {
            store.dispatch("SET_USER_TOKEN", queryData.token || '');
            store.dispatch("SET_USER_INFO", queryData);
            store.dispatch("SET_IS_LOGIN", true);
          }
        }
      }
      next();
    }
  });
});

export default router
