import {GET, POST_JSON} from "@/api/service";

export default {
  /**
   * 获得企业墙列表
   * @returns {*}
   */
  getCompanyWallList() {
    return GET(`/content/getCompanyWallList`);
  },
  /**
   * 模糊搜索获得企业信息
   * @param data
   * name-公司名称模糊
   * pageNum-当前页数
   * pageSize-每页数据
   * @returns {*}
   */
  companyList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/core/companyList?${page}`, {
      name: data.name
    });
  },
  /**
   * 模糊搜索获得企业信息(热门)
   * @param data
   * name-公司名称模糊
   * pageNum-当前页数
   * pageSize-每页数据
   * @returns {*}
   */
  companyHotList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/core/companyListByCombo?${page}`, {
      name: data.name
    });
  },
  /**
   * 获取企业基本信息
   * @param data
   * companyId-公司ID
   * @returns {*}
   */
  getCompanyDetails(data) {
    return GET(`/core/getCompanyInfo`, data);
  }
}
