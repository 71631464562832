import api from "@/api";

export const getDictList = (paramData) => {
  return new Promise((resolve) => {
    try {
      api.system.getDictList(paramData).then(({data, code, msg})=> {
        if (code === 200) {
          resolve(data);
        } else {
          console.log(msg);
        }
      }).catch(e=> console.log(e));
    } catch (e) {
      console.log(e);
    }
  });
}

export const getDictTreeList = (paramData) => {
  return new Promise((resolve) => {
    try {
      api.system.getDictTreeList(paramData).then(({data, code, msg})=> {
        if (code === 200) {
          resolve(data);
        } else {
          console.log(msg);
        }
      }).catch(e=> console.log(e));
    } catch (e) {
      console.log(e);
    }
  });
}

export const formatterDictData = (rows) => {
  rows && rows.forEach((row)=> {
    row.value = row.dictCode;
    row.label = row.dictLabel;
    row.isSelected = false;
  });
  return rows;
}


