import {GET, POST, POST_JSON} from "@/api/service";

export default {
  /**
   * 我的订单列表
   * @param data
   * @returns {*}
   */
  getOrderList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/order/getOrderList?${page}`, {
      title: data.title,
      type: data.type
    });
  }
}
