import Qs from 'qs';
import { Toast } from 'vant';
import { openApi } from '@/api/request'

/**
 * GET请求
 * @param url
 * @param data
 * @param headers
 */
export const GET = (url, data, headers = {})=> {
  data = data || {};
  return openApi({
    url: url,
    method: 'get',
    headers: headers,
    params: data
  }).catch((e)=> {
    Toast({
      message: $t("common.systemError"),
      duration: 5 * 1000
    });
  });
}
/**
 * POST请求
 * @param url
 * @param data
 * @param headers
 */
export const POST = (url, data, headers = {})=> {
  data = data || {};
  let headersDefault = {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  return openApi({
    url: url,
    method: 'post',
    transformRequest: [
      function(data) {
        data = Qs.stringify(data);
        return data;
      }
    ],
    headers: Object.assign(headersDefault, headers),
    data: data
  }).catch((e)=> {
    Toast({
      message: $t("common.systemError"),
      duration: 5 * 1000
    });
  });
}

/**
 * POST请求(JSON)
 * @param url
 * @param data
 * @param headers
 * @returns {*}
 * @constructor
 */
export const POST_JSON = (url, data, headers = {})=> {
  data = data || {};
  let headersDefault = {
    'Content-Type': 'application/json'
  }
  return openApi({
    url: url,
    method: 'post',
    headers: Object.assign(headersDefault, headers),
    data: data
  }).catch((e)=> {
    Toast({
      message: $t("common.systemError"),
      duration: 5 * 1000
    });
  });
}
