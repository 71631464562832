<template>
  <div id="initVan" class="layout-content-sub message-bg">
    <custom-nav-bar :title="$t('myResume.positionCategoryPageTitle')"></custom-nav-bar>
    <div class="layout-content-sub-inner details-page-model bg" style="padding-bottom: 0">
      <div class="position-category-tree-model">
        <div :style="{'height': $px2vw(344)}">
          <!--最多添加-->
          <div class="position-category-num-desc">
            {{$t('myResume.positionCategoryMaxNumberTips.part1')}}{{positionCategoryNum}}{{$t('myResume.positionCategoryMaxNumberTips.part2')}}
          </div>
          <!--已选-->
          <div class="position-category-selected-title">{{$t('myResume.selectedTips')}}</div>
          <ul v-if="submitPositionCategoryList" class="position-category-selected-list">
            <li v-for="(item,index) in submitPositionCategoryList" :key="index">
              <div class="title">{{ item.label }}</div>
              <div class="icon"><van-icon name="cross" @click="submitPositionCategoryDelete(index)" /></div>
            </li>
          </ul>
        </div>

        <!--请选择-->
        <div class="position-category-list-title">{{$t('common.selectPlaceholder')}}</div>
        <div class="tree-dict-list-model position-category-model">
          <div class="left-list">
            <div class="list-item" v-for="item in list" :key="item.value"
                 :class="{'is-selected': currPositionCategoryParent?.value === item.value}"
                 @click="onChangeParent(item)">
              <div class="title ellipsis">{{item.label}}</div>
            </div>
          </div>
          <div class="right-list">
            <div class="list-item" v-for="item in positionCategoryList" :key="item.value"
                 :class="{'is-selected': currPositionCategory?.value === item.value}"
                 @click="onChange(item)">
              <div class="title ellipsis">{{item.label}}</div>
              <van-icon class="icon" name="success"
                        v-if="currPositionCategory?.value === item.value" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="details-page-model-footer white-bg">
      <van-button class="blue-btn"
                  style="height:100%; width: 100%"
                  @click="submit">{{$t('common.btnSaveText')}}</van-button>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "@/utils/utils";
import CustomNavBar from "@/components/custom/CustomNavBar";
import api from "@/api";
import {getDictList, getDictTreeList} from "@/utils/dicts";
export default {
  name: "ResumePositionCategoryEdit",
  components: {CustomNavBar},
  data() {
    return {
      isLoading: false,
      positionCategoryNum: 3,
      submitPositionCategoryList: [],
      list: [],
      positionCategoryList: [],
      currPositionCategory: null,
      currPositionCategoryParent: null
    }
  },
  computed: {
    resumePositionCategoryValues() {
      return this.$store.state.system.resumePositionCategoryValues;
    }
  },
  beforeMount() {
    if(!isEmpty(sessionStorage.getItem("RESUME_POSITION_CATEGORY"))) {
      const RESUME_POSITION_CATEGORY = JSON.parse(sessionStorage.getItem("RESUME_POSITION_CATEGORY")).resumeIntentionVos;
      RESUME_POSITION_CATEGORY && RESUME_POSITION_CATEGORY.forEach((row)=> {
        this.submitPositionCategoryList.push({
          isSelected: false,
          label: this.resumePositionCategoryValues[row.positionCategoryDictId],
          value: row.positionCategoryDictId
        });
      });
    }
    this.getDictList();
  },
  methods: {
    async getDictList() {
      //职位分类
      let paramsData = {
        dictType: "resume_position_category"
      };
      let dictList = await getDictTreeList(paramsData);
      this.list = this.formatterDictRows(dictList.categoryRootList);
      this.currPositionCategoryParent = this.list[0];
      this.positionCategoryList = this.currPositionCategoryParent.children;
    },
    formatterDictRows(rows) {
      let data = [];
      rows && rows.forEach((row, index)=> {
        data.push({
          value: index,
          label: row.categoryName,
          isSelected: false,
          dictItemValues: row.dictItems.map((subRow)=> {return subRow.dictCode}),
          children: row.dictItems.map((subRow)=> {
            return {
              value: subRow.dictCode,
              label: subRow.dictValue,
              isSelected: false
            }
          })
        });
      });
      return data;
    },
    //求职意向一级(切换)
    onChangeParent(obj) {
      this.positionCategoryList = null;
      this.list.forEach((row)=>{
        if(row.value === obj.value) {
          this.positionCategoryList = row.children;
          this.currPositionCategoryParent = row;
        }
      });
    },
    //求职意向二级(切换)
    onChange(positionCategoryRow) {
      //最多添加
      if(!isEmpty(this.submitPositionCategoryList) && this.submitPositionCategoryList.length >= this.positionCategoryNum) {
        this.$message({message: `${$t('myResume.positionCategoryMaxNumberTips.part1')}${this.positionCategoryNum}${$t('myResume.positionCategoryMaxNumberTips.part2')}`});
        return;
      }
      let selectedNum = 0;
      this.submitPositionCategoryList.forEach((row)=> {
        if(row.value === positionCategoryRow.value) {
          selectedNum++;
        }
      });
      if(selectedNum>0) return;

      this.submitPositionCategoryList.push(positionCategoryRow);
    },
    //删除技能
    submitPositionCategoryDelete(idx) {
      this.submitPositionCategoryList.splice(idx, 1);
    },
    async submit() {
      //请选择求职意向
      if (isEmpty(this.submitPositionCategoryList)) {
        this.$message({message: $t('myResume.positionCategoryEmptyTips')});
        return;
      }
      try {
        if (this.isLoading) return;
        this.isLoading = true;
        this.$customLoading.open();

        let submitList = [];
        this.submitPositionCategoryList && this.submitPositionCategoryList.forEach((row)=> {
          submitList.push({
            positionCategoryDictId: row.value
          });
        });

        let {data, code, msg} = await api.resume.updatePersonalIntention({
          resumeIntentionVos: submitList
        });
        if (code === 200) {
          if(data) {
            this.$message({message: $t('common.messages.saveSuccess')});
            this.$router.replace({ name: "MyResume" });
          }
        } else {
          this.$message({message: msg});
        }
        this.isLoading = false;
        this.$customLoading.close();
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        this.$customLoading.close();
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
