import {GET, POST, POST_JSON} from "@/api/service";

export default {
  /**
   * 登录
   * @param data
   * userType: 1.企业用户 | 2.求职用户
   * type: 1.邮箱 | 2.手机号
   * emailOrMobileNO: 手机或邮箱
   * verificationCode: 验证码
   * openid: "xxx"
   * unionid: "xxx"
   * @returns {*}
   */
  login(data) {
    return POST_JSON(`/sys/user/loginByPassword`, data);
  },
  /**
   * 获取登录手机或邮箱验证码（发送验证码）
   * @param data
   * captchaCode: 验证码
   * type: 1.邮箱 | 2.手机号
   * emailOrMobileNO: 手机号或邮箱
   * uuid: 图形验证码接口返回的uuid
   * @returns {*}
   */
  sendPhoneEmailCode(data) {
    return POST_JSON(`/sys/user/getLoginVerificationCode`, data);
  },
  /**
   * 获取图形验证码
   * @returns {*}
   */
  getPicVerificationCode() {
    return POST(`/sys/user/getCaptchaCode`)
  },
  /**
   * 修改密码
   * @param data
   * userType-用户类型: 1企业用户 | 2.求职用户
   * type: 1.邮箱 | 2.手机号
   * emailOrMobileNO-修改密码的手机号或邮箱
   * password-密码
   * confirmPassword-确认密码
   * verificationCode-找回密码的验证码
   * @returns {*}
   */
  updatePassword(data) {
    return POST_JSON(`/sys/user/updatePassword`, data)
  },
  /**
   * 求职者注册
   * @param data
   * type: 1.邮箱 | 2.手机号
   * password-密码
   * confirmPassword-确认密码
   * verificationCode-验证码
   * openid-当前小程序授权获得openid，没有填空
   * unionid-小程序unionid，没有填空
   * emailOrMobileNO-注册手机号或者邮箱
   * @returns {*}
   */
  applicantRegister(data) {
    return POST_JSON(`/sys/user/applicantRegister`, data)
  },
  /**
   * 用户注册(B端)-公司账号
   * @param data
   * userType: 1.企业用户 | 2.求职用户（目前只接受类型1）
   * applyCompanyFlag-是否申请企业信息: true.申请企业信息，填写企业信息内容 | false.选择已有企业
   * companyId-公司id: 如果applyCompanyFlag为false,则必须填写此值
   * email-邮箱: 账号的邮箱
   * verificationCode-邮箱验证码
   * 以下为applyCompanyFlag:true,需要填写内容
   * companyName-企业名称
   * companyLogoUrl-上传logo图片的地址
   * companyOfficialWebsite-官网地址
   * companyWeComQRCode-企业微信二维码
   * userOpenId-当前用户openId
   * userUnionId-当前用户unionId
   * companyCountry-企业国家
   * companyProvince-省份
   * companyCity-城市
   * companyAddress-地址
   * password-输入登录密码
   * confirmPassword-确认登录密码
   * mobileNo-手机号（选填）【暂时不传】
   * @returns {*}
   */
  companyRegister(data) {
    return POST_JSON(`/sys/user/register`, data)
  },
  /**
   * 微信登录获取OpenId或者UnionId
   * @param data (code: 小程序code)
   * @returns {*}
   */
  getWechatMiniInfo(data) {
    return POST(`/sys/user/wechatMiniInfo`, data)
  },
  /**
   * 微信一键注册登录
   * @returns {*}
   */
  registerLoginByWXApp(){
    return POST(`/sys/user/registerorLoginByWXapp`)
  }
}
