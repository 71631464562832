import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { Toast } from 'vant';
import {isWx} from "@/utils/utils";

const langCode = localStorage.getItem('language') ? localStorage.getItem('language') : 'zh';
const openApi = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 20000,
    headers: {
      "Content-Type": "application/json",
      cultureCode: langCode === 'en' ? 'en-us' : ' zh-cn',
    }
})

openApi.interceptors.request.use(config => {
  if (store.state.user.userToken && config.headers) {
    config.headers.Authorization = `Bearer ${store.state.user.userToken}`;
  }
  return config
}, error => {
  return Promise.reject(error)
})

// response interceptor
openApi.interceptors.response.use(
  response => {
    if(response.data.code === 401) {
      store.commit("REMOVE_USER_INFO");
      store.commit("REMOVE_USER_TOKEN");
      store.commit("REMOVE_IS_LOGIN");
      response.data.msg = $t('common.pleaseLoginFirst');
      isWx().then(type => {
        store.commit("SET_MINI_APP", type === "mini-wx");
        if (type === "mini-wx") {
          let langCode = localStorage.getItem('language');
          window.wx.miniProgram.redirectTo({url:`/pages/login/login?lang=${langCode}`});
        } else if(type === "wx") {
          //微信浏览器
        } else {
          const fullPath = router.currentRoute.value.fullPath;
          let params = { name: 'CustomerLogin', query: {redirect: fullPath} }
          if(fullPath === '/customerLogin') {
            params = { name: 'CustomerLogin' }
          }
          router.replace(params);
        }
      });
    }
    if (response.data.code !== 401 && response.data.code !== 200) {
      response.data.msg = langCode === 'en' ? response.data.msgEn : response.data.msg;
    }
    return response.data
  },
  error => {
    const { response } = error
    if(!response) {
      Toast({
        message: $t("common.systemError"),
        duration: 5 * 1000
      });
    }
    return response ? response.data : Promise.reject(error)
  }
)

export { openApi }
