// import router from '@/router';
const FORM_PAGE_PAGE = "FORM_PAGE_PAGE";
export default ({
  state: ()=> ({
    formPagePath: sessionStorage.getItem(FORM_PAGE_PAGE),
    provinceCityList: [],
    provinceCityListAll: [],
    resumeSkillValues: null,
    resumePositionCategoryValues: null,
    menuParams: null,
    homeSearchParams: null,
    uploadSelected: false,
    isMiniApp: false
  }),
  actions: {
  },
  mutations: {
    SET_FORM_PAGE_PATH(state, path) {
      state.formPagePath = path;
      sessionStorage.setItem(FORM_PAGE_PAGE, path);
    },
    SET_MENU_PARAMS(state, menuParams) {
      state.menuParams = menuParams;
      sessionStorage.setItem("MENU_PARAMS", menuParams);
    },
    REMOVE_MENU_PARAMS(state) {
      state.menuParams = null;
      sessionStorage.setItem("MENU_PARAMS", "");
    },
    SET_HOME_SEARCH_PARAMS(state, homeSearchParams) {
      state.homeSearchParams = homeSearchParams;
      sessionStorage.setItem("HOME_SEARCH_PARAMS", homeSearchParams);
    },
    REMOVE_HOME_SEARCH_PARAMS(state) {
      state.homeSearchParams = null;
      sessionStorage.setItem("HOME_SEARCH_PARAMS", "");
    },
    SET_PROVINCE_CITY_LIST(state, provinceCityList){
      state.provinceCityList = provinceCityList;
      sessionStorage.setItem('PROVINCE_CITY_LIST', JSON.stringify(provinceCityList));
    },
    SET_PROVINCE_CITY_LIST_ALL(state, provinceCityListAll){
      state.provinceCityListAll = provinceCityListAll;
      sessionStorage.setItem('PROVINCE_CITY_LIST_ALL', JSON.stringify(provinceCityListAll));
    },
    SET_UPLOAD_SELECTED(state, uploadSelected){
      state.uploadSelected = uploadSelected;
      sessionStorage.setItem('UPLOAD_SELECTED', uploadSelected);
    },
    SET_MINI_APP(state, isMiniApp) {
      state.isMiniApp = isMiniApp;
    },
    SET_RESUME_SKILL_VALUES(state, resumeSkillValues) {
      state.resumeSkillValues = resumeSkillValues;
      localStorage.setItem("RESUME_SKILL_VALUES", resumeSkillValues);
    },
    SET_RESUME_POSITION_CATEGORY_VALUES(state, resumePositionCategoryValues) {
      state.resumePositionCategoryValues = resumePositionCategoryValues;
      localStorage.setItem("RESUME_POSITION_CATEGORY_VALUES", resumePositionCategoryValues);
    }
  }
})
