import {GET, POST, POST_JSON} from "@/api/service";

export default {
  /**
   * 获得求职者列表
   * @returns {*}
   * @param data (pageNum | pageSize | hostFlag: 1仅查看热门 0:所有)
   */
  getResumeList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/resume/getUserApplicantList?${page}`, {
      searchText: data.searchText,
      diplomaTypes: data.diplomaTypes,
      workYears: data.workYears,
      positionCategoryDictId: data.positionCategoryDictId,
      workProvince: data.workProvince,
      workCity: data.workCity
    });
  },
  /**
   * 获得求职者信息
   * @param params
   * @returns {*}
   */
  getResumeDetail(params) {
    return POST_JSON(`/resume/getUserApplicantInfo`, params);
  },
  /**
   * 获得我的简历内容
   * @returns {*}
   */
  getMyResumeInfo() {
    return GET(`/resume/getInfo`);
  },
  /**
   * 保存工作经历
   * @param data
   * @returns {*}
   */
  saveExperience(data) {
    return POST_JSON(`/resume/saveExperience`, data);
  },
  /**
   * 修改工作经历
   * @param data
   * @returns {*}
   */
  updateExperience(data) {
    return POST_JSON(`/resume/updateExperience`, data);
  },
  /**
   * 获取工作经历
   * @param data
   * @returns {*}
   */
  getExperienceInfo(data) {
    return POST_JSON(`/resume/getExperience`, data);
  },
  /**
   * 删除工作经历
   * @param data
   * @returns {*}
   */
  deleteExperience(data) {
    return POST_JSON(`/resume/deleteExperience`, data);
  },
  /**
   * 获取工作经历
   * @param data
   * @returns {*}
   */
  getEducationInfo(data) {
    return POST_JSON(`/resume/getEducation`, data);
  },
  /**
   * 保存教育经历
   * @param data
   * @returns {*}
   */
  saveEducation(data) {
    return POST_JSON(`/resume/saveEducation`, data);
  },
  /**
   * 修改教育经历
   * @param data
   * @returns {*}
   */
  updateEducation(data) {
    return POST_JSON(`/resume/updateEducation`, data);
  },
  /**
   * 删除教育经历
   * @param data
   * @returns {*}
   */
  deleteEducation(data) {
    return POST_JSON(`/resume/deleteEducation`, data);
  },
  /**
   * 获取职业技能
   * @returns {*}
   */
  getSkillList() {
    return POST_JSON(`/resume/getSkillList`);
  },
  /**
   * 保存职业技能
   * @param data
   * @returns {*}
   */
  batchSaveSkill(data) {
    return POST_JSON(`/resume/batchSaveSkill`, data);
  },
  /**
   * 简历提交
   * @param data resumeId
   * @returns {*}
   */
  submitResume(data) {
    return POST_JSON(`/resume/submitResume`, data);
  },
  /**
   * 更新简历中求职者意向
   * @param data
   * positionCategoryDictId-求职意向数据字典Id
   * positionName-手输求职意向(选择字典传"")
   * @returns {*}
   */
  updatePersonalIntention(data) {
    return POST_JSON(`/resume/updatePersonalIntention`, data);
  },
  /**
   * 下载完整简历
   * @param data
   * resumeBaseInfoId-简历ID
   * @returns {*}
   */
  downLoadResumeFile(data) {
    return GET(`/resume/downLoadResumeFile`, data);
  },
  /**
   * 更新个人总结
   * @param data
   * personalSummary-个人简介
   * @returns {*}
   */
  updatePersonalSummary(data) {
    return POST_JSON(`/resume/updatePersonalSummary`, data);
  },
  /**
   * 更求职者在职状态
   * @param data
   * jobStatus-在职状态: 0.未知 | 1.在职-月内到岗 | 2.在职-考虑机会 | 3.离职
   * @returns {*}
   */
  updateApplicantStatus(data) {
    return POST_JSON(`/resume/updateApplicantStatus`, data);
  },
  /**
   * 更新求职者语言熟练度
   * proficiencyLangeZhCn-中文熟练度(1，2，3，4，5)
   * proficiencyLangeEnUs-英文熟练度(1，2，3，4，5)
   * proficiencyLangeDeDe-德语熟练度(1，2，3，4，5)
   * proficiencyLangeOther-其他
   * @param data
   * @returns {*}
   */
  updateApplicantProficiencyLange(data) {
    return POST_JSON(`/sys/user/updateApplicantProficiencyLange`, data);
  },
  /**
   * 清空旧系统工作经历
   * @returns {*}
   */
  clearOldExperience() {
    return POST(`/resume/emptyExperience`);
  },
  /**
   * 清空旧系统教育经历
   * @returns {*}
   */
  clearOldEducation() {
    return POST(`/resume/emptyEducation`);
  }
}
