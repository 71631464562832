export const TOKEN_KEY = 'auth_token';
export const USER_INFO = 'user_info';
export const IS_LOGIN = 'user_is_login';
export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
}

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    return token
  } else {
    localStorage.setItem(TOKEN_KEY, "");
  }
  return ''
}

export const removeToken = () => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) localStorage.removeItem(TOKEN_KEY)
}

export const setUserInfo = (userInfo) => {
  localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
}

export const getUserInfo = () => {
  const userInfo = localStorage.getItem(USER_INFO);
  if (userInfo) {
    return JSON.parse(userInfo)
  } else {
    localStorage.setItem(USER_INFO, "");
  }
  return ''
}

export const removeUserInfo = () => {
  const userInfo = localStorage.getItem(USER_INFO)
  if (userInfo) localStorage.removeItem(USER_INFO)
}

export const setIsLogin = (isLogin) => {
  localStorage.setItem(IS_LOGIN, JSON.stringify(isLogin));
}

export const getIsLogin = () => {
  const isLogin = localStorage.getItem(IS_LOGIN);
  if (isLogin) {
    return JSON.parse(isLogin)
  } else {
    localStorage.setItem(IS_LOGIN, "");
  }
  return ''
}

export const removeIsLogin = () => {
  const isLogin = localStorage.getItem(IS_LOGIN)
  if (isLogin) localStorage.removeItem(IS_LOGIN)
}