import {GET, POST, POST_JSON} from "@/api/service";

export default {
  /**
   * 获取公开课列表
   * @param data
   * @returns {*}
   */
  getPublicClassList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/class/getPublicClassList?${page}`, {
      timeStatus: data.timeStatus,
      title: data.title,
      type: data.type,
      languages: data.languages
    });
  },
}
