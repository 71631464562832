import {POST_JSON} from "@/api/service";

export default {
  /**
   * 获取职涯辅导列表
   * @param data
   * @returns {*}
   */
  getCareerCoachingList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/coaching/getCareerCoachingList?${page}`);
  },
  /**
   * 职涯辅导详情
   * @param data
   * @returns {*}
   */
  getCareerCoachingInfo(data) {
    return POST_JSON(`/coaching/getCareerCoachingInfo`, data)
  },
  /**
   * 职涯辅导预约
   * @param data
   * @returns {*}
   */
  saveCareerCoachingSubscribe(data) {
    return POST_JSON(`/subscribe/insertCareerCoachingSubscribe`, data);
  },
  /**
   * 获取我的培训列表
   * @param data
   * @returns {*}
   */
  getCareerCoachingSubscribeList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/subscribe/getCareerCoachingSubscribeList?${page}`);
  }
}
