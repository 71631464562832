import {GET, POST_JSON} from "@/api/service";

export default {
  /**
   * 获取首页数据
   * @param data
   * deviceCode-端分类: 1.MB | 2.PC
   * @returns {*}
   */
  getHomeList(data) {
    return POST_JSON(`/content/homePage?deviceCode=${data.deviceCode}`);
  },
  /**
   * 获得新闻列表
   * @returns {*}
   */
  getNewsList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/content/newsList?${page}`, {
      mainCategoryId: data.mainCategoryId,
      categoryId: data.categoryId,
    });
  },
  /**
   * 获得新闻一级列表
   * @returns {*}
   */
  getNewsCategoryList() {
    return POST_JSON(`/content/categoryMains`);
  },
  /**
   * 获得新闻二级列表
   * @param data (categoryId: 一级目录ID)
   * @returns {*}
   */
  getNewsSecondCategoryList(data) {
    return POST_JSON(`/content/categories`, data);
  },
  /**
   * 获取新闻详情
   * @returns {*}
   */
  getNewsDetail(data) {
    return POST_JSON(`/content/newsDetail`, data)
  },
  /**
   * 获得轮播图列表
   * @param params
   * type: 1.首页 | 2.职位列表 | 3.求职者列表
   * deviceCode: 1.MB | 2.PC
   * @returns {*}
   */
  getBannerList(params) {
    return GET(`/content/getBannerList`, params);
  },
  /**
   * 获得套餐商品列表
   * @returns {*}
   */
  getProductList() {
    return GET(`/content/getProductList`);
  },
  /**
   * 获得套餐商品详情
   * @returns {*}
   */
  getProductDetail(params) {
    return GET(`/content/getProductById`, params);
  },
  /**
   * 收藏
   * @param data
   * favorContentId-收藏id
   * type-收藏类型: 1.职位 | 2.求职者
   * @returns {*}
   */
  updateFavor(data) {
    return POST_JSON(`/content/updateFavor`, data)
  },
  /**
   * 获取收藏列表
   * @returns {*}
   */
  getFavorList() {
    return POST_JSON(`/content/favorList`)
  }
}
