import {GET, POST_JSON} from "@/api/service";

export default {
  /**
   * 获取我的证书列表
   * @param data
   * pageNum-当前页数
   * pageSize-每页数据
   * @returns {*}
   */
  getCertificateList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/certificate/getCertificateList?${page}`);
  },
  /**
   * 获取我的证书详情
   * @param data
   * @returns {*}
   */
  getCertificateById(data) {
    return POST_JSON(`/certificate/getCertificateById`, data)
  },
  /**
   * 删除证书
   * @param data
   * @returns {*}
   */
  certificateRemove(data) {
    return POST_JSON(`/certificate/removeById`, data)
  },
  /**
   * 新增证书
   * @param data
   * @returns {*}
   */
  submitCertificateAdd(data) {
    return POST_JSON(`/certificate/insertCertificate`, data)
  },
  /**
   * 修改证书
   * @param data
   * @returns {*}
   */
  submitCertificateEdit(data) {
    return POST_JSON(`/certificate/editCertificate`, data)
  }
}
