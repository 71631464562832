<template>
  <div class="layout-content-main" :class="{'grey-bg': isGreyBg}">
      <div ref="content" id="content"
           class="layout-content"
           :class="{
            'is-full': isFull,
            'img-bg': isImgBg,
            'grey-bg': isGreyBg,
            'no-padding-scroll': isContentNoPadding,
            'no-scroll': isDisableScroll}">
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.name" />
            </keep-alive>
            <component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.name" />
          </router-view>
      </div>
      <div ref="layoutFooter" id="layoutFooter" v-show="isFooter"
           class="layout-footer">
        <page-footer :is-login="isLogin" />
      </div>
  </div>
</template>

<script>
import PageFooter from './Footer';
export default {
  name: 'AppMain',
  components: { PageFooter },
  data() {
    return {
      isSafe: false,
      isFooter: true,
      isContentNoPadding: false,
      isImgBg: false,
      isGreyBg: false,
      isDisableScroll: false,
      footerList: ['Home','ProfileIndex','ResumeList','PositionList'],
      contentNoPaddingList: [''],
      disableScrollList: ['Home','TrainIndex'],
      greyBgList: ['ProfileIndex'],
      imgBgList: [],
      isFull: false
    };
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    isLogin() {
      return this.$store.state.user.isLogin;
    }
  },
  watch: {
    '$route.name': {
      handler(val) {
        this.resizeLayout(val);
        if (val === 'Home' || val === 'ProfileIndex') {
          this.$refs.content.scrollTop = 0;
        }
        this.isFull = val === 'PositionDetails';
        this.isFooter = val !== 'PositionDetails';
      },
      deep: true
    }
  },
  created() {
    this.resizeLayout(this.$route.name);
  },
  methods: {
    resizeLayout(val) {
      this.isFooter = this.footerList.includes(val);
      this.isImgBg = this.imgBgList.includes(val);
      this.isGreyBg = this.greyBgList.includes(val);
      this.isContentNoPadding = this.contentNoPaddingList.includes(val);
      this.isDisableScroll = this.disableScrollList.includes(val);
      this.isFull = val === 'PositionDetails';
      this.isFooter = val !== 'PositionDetails';
    }
  }
};
</script>
