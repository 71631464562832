<template>
  <van-image :src="state.src"
             :error-icon="errorIcon"
             :style="{
               'height': $px2vw(height),
               'width': $px2vw(width),
               'border-radius': $px2vw(radius)
             }"
             :radius="radius"
             :lazy-load="lazyLoad"
             :fit="fit">
    <template v-slot:default>

    </template>
    <template v-if="!isErrorImg" v-slot:error>{{errorText}}</template>
  </van-image>
</template>

<script setup name="CustomImage">
import { reactive, watch } from 'vue';
import { isEmpty } from "@/utils/utils";
const props = defineProps({
  src: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: ''
  },
  height: {
    type: Number,
    default: 136
  },
  width: {
    type: Number,
    default: 136
  },
  radius: {
    type: Number,
    default: 14
  },
  fit: {
    type: String,
    default: "cover"
  },
  isErrorImg: {
    type: Boolean,
    default: true
  },
  errorIcon: {
    type: String,
    default: "photo-fail"
  },
  errorText: {
    type: String,
    default() {
      return $t('common.loadFailed');
    }
  },
  lazyLoad: {
    type: Boolean,
    default: false
  }
});

const state = reactive({
  src: null,
  avatarPic: require("@/assets/images/default_avatar.jpg"),
  logoPic: require("@/assets/images/default.jpg"),
  defaultPic: require("@/assets/images/default.jpg")
});

const init = () => {
  if(isEmpty(props.src)) {
    let picSrc = '';
    switch (props.type) {
      case 'avatar':
        picSrc = state.avatarPic;
        break;
      case 'logo':
        picSrc = state.logoPic;
        break;
      default:
        picSrc = state.defaultPic;
    }
    state.src = picSrc;
  } else {
    state.src = props.src;
  }
}

init();
watch(props,()=> {
  init();
});
</script>
<!--<script>-->
<!--import { reactive, watch } from 'vue';-->
<!--import { isEmpty } from "@/utils/utils";-->
<!--export default {-->
<!--  name: "CustomImage",-->
<!--  props: {-->
<!--    src: {-->
<!--      type: String,-->
<!--      default: ''-->
<!--    },-->
<!--    type: {-->
<!--      type: String,-->
<!--      default: 'default'-->
<!--    },-->
<!--    height: {-->
<!--      type: Number,-->
<!--      default: 136-->
<!--    },-->
<!--    width: {-->
<!--      type: Number,-->
<!--      default: 136-->
<!--    },-->
<!--    radius: {-->
<!--      type: Number,-->
<!--      default: 14-->
<!--    },-->
<!--    fit: {-->
<!--      type: String,-->
<!--      default: "cover"-->
<!--    },-->
<!--    isErrorImg: {-->
<!--      type: Boolean,-->
<!--      default: true-->
<!--    },-->
<!--    errorIcon: {-->
<!--      type: String,-->
<!--      default: "photo-fail"-->
<!--    },-->
<!--    errorText: {-->
<!--      type: String,-->
<!--      default: "加载失败"-->
<!--    },-->
<!--    lazyLoad: {-->
<!--      type: Boolean,-->
<!--      default: false-->
<!--    }-->
<!--  },-->
<!--  setup(props) {-->
<!--    const state = reactive({-->
<!--      src: null,-->
<!--      avatarPic: require("@/assets/images/default_avatar.jpg"),-->
<!--      logoPic: require("@/assets/images/default.jpg"),-->
<!--      defaultPic: require("@/assets/images/default.jpg")-->
<!--    });-->

<!--    const init = () => {-->
<!--      if(isEmpty(props.src)) {-->
<!--        let picSrc = '';-->
<!--        switch (props.type) {-->
<!--          case 'avatar':-->
<!--            picSrc = state.avatarPic;-->
<!--            break;-->
<!--          case 'logo':-->
<!--            picSrc = state.logoPic;-->
<!--            break;-->
<!--          default:-->
<!--            picSrc = state.defaultPic;-->
<!--        }-->
<!--        state.src = picSrc;-->
<!--      } else {-->
<!--        state.src = props.src;-->
<!--      }-->
<!--    }-->

<!--    init();-->
<!--    watch(props,()=> {-->
<!--      init();-->
<!--    });-->

<!--    return {-->
<!--      state-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->

<style lang="scss" scoped>
.test {
  width: 136px;
  height: 136px;
}
</style>
