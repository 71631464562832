import user from './modules/user';
import system from './modules/system';
import jobs from './modules/jobs';
import company from './modules/company';
import message from './modules/message';
import resume from './modules/resume';
import content from './modules/content';
import openClass from "./modules/openClass";
import order from "./modules/order";
import subscribe from "./modules/subscribe";
import training from "./modules/training";
import certificate from "./modules/certificate";

export default {
  user,
  system,
  jobs,
  company,
  message,
  resume,
  content,
  openClass,
  order,
  subscribe,
  training,
  certificate
}
