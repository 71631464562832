import {GET, POST, POST_JSON} from "@/api/service";

export default {
  /**
   * 上传文件
   * @param data
   * file-上传的文件
   * folderNO-上传到目录文件的编号(枚举，后端提供): 1.头像 | 2.文件(非1、3、4、7) | 3.简历 | 4.营业执照 | 7.个人证书
   * @returns {*}
   */
  uploadFile(data) {
    return POST_JSON(`/sys/media/uploadFile`, data, {
      'Content-Type': 'multipart/form-data'
    })
  },
  /**
   * 获取字典列表
   * @param data
   * @returns {*}
   */
  getDictList(data) {
    return POST_JSON(`/sys/dict/getMultiDicts`, data);
  },
  /**
   * 获取字典列表-二级
   * @param data
   * @returns {*}
   */
  getDictTreeList(data) {
    return POST_JSON(`/sys/dict/getDictTreeValues`, {
      dictType: data.dictType,
      searchText: data.searchText
    });
  },
  /**
   * 完善用户邮箱
   * @param data
   * email-邮箱
   * verificationCode-验证码
   * @returns {*}
   */
  fillUserEmail(data) {
    return POST_JSON(`/sys/user/fillUserEmail`, data)
  },
  /**
   * 获取企业用户中心主页
   * @returns {*}
   */
  getCompanyUserCenterInfo() {
    return POST(`/sys/user/getCompanyUserCenterInfo`)
  },
  /**
   * 获取个人中心主页
   * @returns {*}
   */
  getUserCenterInfo() {
    return POST(`/sys/user/getUserCenterInfo`)
  },
  /**
   * 修改个人信息
   * @param data
   * @returns {*}
   */
  updateApplicantUserInfo(data) {
    return POST_JSON(`/sys/user/updateApplicantUserInfo`, data)
  },
  /**
   * 更新我的消息为已读
   * @param data
   * id-消息ID
   * @returns {*}
   */
  updateMsgToReadStatus(data) {
    return POST_JSON(`/sys/user/updateMsgToReadStatus`, data)
  },
  /**
   * 上传简历
   * @param data
   * @returns {*}
   */
  uploadUserResumeFile(data) {
    return POST_JSON(`/sys/media/uploadUserResumeFile`, data)
  }
}
