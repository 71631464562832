<template>
  <div class="loading-main">
    <div class="loading-inner">
      <div class="van-loading van-loading--circular van-loading--vertical" aria-live="polite" aria-busy="true">
        <span class="van-loading__spinner van-loading__spinner--circular" style="width: 24px; height: 24px;">
          <svg class="van-loading__circular" viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20" fill="none"></circle>
        </svg>
      </span>
        <span class="van-loading__text" id="loadingText">{{loadingText}}</span></div>
<!--      <div class="loading-text">{{loadingText}}</div>-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      color: "",
      loadingText: $t('common.loadingText')
    }
  }
}
</script>

<style lang="scss" scoped>
.van-loading {
  color: var(--primary-color);
}
.van-loading__text {
  color: var(--primary-color);
}
.loading-main {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(255,255,255,.5);
}
.loading-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-width: 100px;
}
.loading-text {
  text-align: center;
  color: var(--primary-color);
}
.loading-wave {
  display: flex;
  justify-content: space-between;
  margin: 15px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  & .rect {
    background-color: var(--primary-color);
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: wave-stretch-delay 1.2s infinite ease-in-out;
    animation: wave-stretch-delay 1.2s infinite ease-in-out;
  }
  & .rect1 {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
  }
  & .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  & .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  & .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  & .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
}

@-webkit-keyframes wave-stretch-delay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes sk-waveStretchDelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
</style>
