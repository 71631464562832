<template>
  <div class="layout-tab-bar">
    <div v-for="(item,index) in navList"
         :key="index"
         class="item-tab"
         :class="{'is-active': $route.name === item.name}">
      <div class="inner" @click="handleLink(item)">
        <div class="icon">
          <img :src="$route.name === item.name ? item.activeSrc : item.src" />
        </div>
        <div class="title">{{item.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      navList: [],
      navListB: [{
        title: $t("home.navList.index"),
        name: "Home",
        activeSrc: require("../../assets/images/nav/home_on.png"),
        src: require("../../assets/images/nav/home.png")
      },{
        title: $t("home.navList.recruit"),
        name: "ResumeList",
        activeSrc: require("../../assets/images/nav/resume_on.png"),
        src: require("../../assets/images/nav/resume.png")
      },{
        title: $t("home.navList.train"),
        name: "TrainIndex",
        activeSrc: require("../../assets/images/nav/train_on.png"),
        src: require("../../assets/images/nav/train.png")
      },{
        title: $t("home.navList.profile"),
        name: "ProfileIndex",
        activeSrc: require("../../assets/images/nav/profile_on.png"),
        src: require("../../assets/images/nav/profile.png")
      }],
      navListC: [{
        title: $t("home.navList.index"),
        name: "Home",
        activeSrc: require("../../assets/images/nav/home_on.png"),
        src: require("../../assets/images/nav/home.png")
      },{
        title: $t("home.navList.jobs"),
        name: "PositionList",
        activeSrc: require("../../assets/images/nav/jobs_on.png"),
        src: require("../../assets/images/nav/jobs.png")
      },{
        title: $t("home.navList.train"),
        name: "TrainIndex",
        activeSrc: require("../../assets/images/nav/train_on.png"),
        src: require("../../assets/images/nav/train.png")
      },{
        title: $t("home.navList.profile"),
        name: "ProfileIndex",
        activeSrc: require("../../assets/images/nav/profile_on.png"),
        src: require("../../assets/images/nav/profile.png")
      }]
    }
  },
  computed: {
    isConsumer() {
      let userType = this.$store.state.user.userInfo?.userType || 2;
      return userType === 2 || userType === "2";
    }
  },
  watch: {
    isLogin(val) {
      this.init(val);
    }
  },
  created() {
    this.init(this.isLogin);
  },
  methods: {
    init(isLogin) {
      if(this.isConsumer) {
        this.navList = this.navListC;
      } else {
        if(isLogin) {
          this.navList = this.navListB;
        } else {
          this.navList = this.navListC;
        }
      }
    },
    handleLink({ name }) {
      this.$router.push({ name: name });
    }
  }
}
</script>

<style scoped>

</style>
