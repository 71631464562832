import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./lang";
import { Toast, Dialog, Button, Image as VanImage, DatetimePicker, Field, Uploader, Tabbar, TabbarItem, Swipe, SwipeItem, Loading, Icon, Tab, Tabs, PullRefresh, List, Checkbox, CheckboxGroup, Popup, Picker, Empty, RadioGroup, Radio } from 'vant';
import 'vant/lib/index.css';
import '@/styles/variables.css';
import CustomLoading from "@/components/custom/CustomLoading.js";

const app = createApp(App);
app.config.globalProperties.$t = i18n.global.t;
window.$t = i18n.global.t;
const $Toast = options => {
  return Toast({
    ...options,
    iconSize: options.iconSize ? options.iconSize : 36,
    className: 'message-index'
  });
};
app.config.globalProperties.$message = $Toast;
window.$message = $Toast;

const $Dialog = options => {
  return Dialog({
    ...options,
    cancelButtonColor: options.cancelButtonColor ? options.cancelButtonColor : "#00A6E3",
    confirmButtonColor: options.confirmButtonColor ? options.confirmButtonColor : "#00A6E3"
  });
};
app.config.globalProperties.$messageBox = $Dialog;
window.$messageBox = $Dialog;
app.config.globalProperties.$customLoading = CustomLoading;
window.$customLoading = CustomLoading;

const px2vw = (px)=> {
  if(/%/ig.test(px)){
    return px
  }else{
    return `${px / 750 * 100}vw`
  }
}
app.config.globalProperties.$px2vw = px2vw

app.use(Button);
app.use(VanImage);
app.use(DatetimePicker);
app.use(Field);
app.use(Uploader);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Swipe);
app.use(SwipeItem);
app.use(Loading);
app.use(Icon);
app.use(Tab);
app.use(Tabs);
app.use(PullRefresh);
app.use(List);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Popup);
app.use(Picker);
app.use(Empty);
app.use(Radio);
app.use(RadioGroup);
app.use(Dialog);
app.use(store);
app.use(router);
app.mount('#app')
