/**
 * 判断是否为空
 * undefined => true
 * null => true
 * [] => true
 * {} => true
 * "" => true
 * "    " => true
 * @param target 目标
 * @returns {boolean}
 */
export function isEmpty(target) {
  if (target === undefined) return true;
  if (target === 'undefined') return true;
  if (target === null) return true;
  if (target === 'null') return true;
  if (isArray(target) && target.length === 0) return true;
  if (isObject(target) && Object.getOwnPropertyNames(target).length === 0) return true;
  if (isNumber(target) && target === -1) return true;
  if (`${target}`.trim().length === 0) return true;
  return false;
}

export function isString(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'String';
}

export function isNumber(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Number';
}

export function isBoolean(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Boolean';
}

export function isFunction(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Function';
}

export function isNull(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Null';
}

export function isUndefined(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Undefined';
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Object';
}

export function isArray(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Array';
}

export function isDate(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Date';
}

export function isRegExp(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'RegExp';
}

export function isError(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Error';
}

export function isSymbol(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Symbol';
}

export function isPromise(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Promise';
}

export function isSet(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Set';
}

/**
 * 是否是数字
 * @param str
 * @returns {boolean}
 */
export function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str);
}

/**
 * 字符大小转换
 * @param str
 * @param type 1:首字母大写 2：首字母小写 3：大小写转换 4：全部大写 5：全部小写
 * @returns {string}
 */
export function changeCase(str = '', type) {
  type = type || 4;
  switch (type) {
    case 1:
      return str.replace(/\b\w+\b/g, function(word) {
        return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
      });
    case 2:
      return str.replace(/\b\w+\b/g, function(word) {
        return word.substring(0, 1).toLowerCase() + word.substring(1).toUpperCase();
      });
    case 3:
      return str.split('').map(function(word) {
        if (/[a-z]/.test(word)) {
          return word.toUpperCase();
        } else {
          return word.toLowerCase();
        }
      }).join('');
    case 4:
      return str.toUpperCase();
    case 5:
      return str.toLowerCase();
    default:
      return str;
  }
}

/**
 * 转大写+下划线
 * @param param
 * @returns {string}
 */
export function toUpperCaseUnderline(param) {
  let result = '';
  if (param != null && param.length > 0) {
    result += param.substring(0, 1).toUpperCase();
    for (let i = 1; i < param.length; i++) {
      const s = param.substring(i, i + 1);
      if (s === s.toUpperCase() && (/^[0-9A-Z]*$/.test(s.charAt(0)))) {
        result += '_';
      }
      result += s.toUpperCase();
    }
  }
  return result;
}

/**
 * 将字符串转驼峰
 * @param str
 */
export function toHumpFormat(str = '') {
  const re = /-(\w)/g;
  str = str.replace(re, function($0, $1) {
    return $1.toUpperCase();
  });
  return str;
}

/**
 * 千分符显示
 * @param num 数字
 * @returns {string}
 */
export function thousandSymbolsStr(num) {
  return num.toString().replace(/(\d{1,3})(?=(\d{3})+$)/g, '$1,');
}

/**
 * 列表计算排序
 * @param propertyName 排序列名
 * @param type asc升序/desc降序
 * @returns {function(...[*]=)}
 */
export function listCompareSort(propertyName, type) {
  if (type === 'asc') {
    return function(obj1, obj2) {
      const value1 = obj1[propertyName];
      const value2 = obj2[propertyName];
      if (value2 > value1) {
        return 1;
      } else if (value2 < value1) {
        return -1;
      } else {
        return 0;
      }
    };
  } else if (type === 'desc') {
    return function(obj1, obj2) {
      const value1 = obj1[propertyName];
      const value2 = obj2[propertyName];
      if (value2 < value1) {
        return 1;
      } else if (value2 > value1) {
        return -1;
      } else {
        return 0;
      }
    };
  }
}

/**
 * 文本域（内容转html）
 * @param str 格式化字符串
 * @returns {string}
 */
export function textareaConvertHtml(str) {
  return str.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' ');
}

/**
 * 文本域（html转内容）
 * @param str 格式化字符串
 * @returns {*|void|string}
 */
export function htmlConvertTextarea(str) {
  return str.replace(/<br\/>/g, '\r\n');
}

/**
 * 截取文本
 * @param str 要截取的字符串
 * @param startNum 开始值
 * @param endNum 结束值
 * @returns {string}
 */
export function subStr(str, startNum, endNum) {
  return str.substring(startNum, endNum);
}

/**
 * 深拷贝(对象)
 * @param obj
 * @returns {any}
 */
export function deepClone(obj) {
  const _toString = Object.prototype.toString;
  // null, undefined, non-object, function
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  // DOM Node
  if (obj.nodeType && 'cloneNode' in obj) {
    return obj.cloneNode(true);
  }
  // Date
  if (_toString.call(obj) === '[object Date]') {
    return new Date(obj.getTime());
  }
  // RegExp
  if (_toString.call(obj) === '[object RegExp]') {
    const flags = [];
    if (obj.global) {
      flags.push('g');
    }
    if (obj.multiline) {
      flags.push('m');
    }
    if (obj.ignoreCase) {
      flags.push('i');
    }
    return new RegExp(obj.source, flags.join(''));
  }
  const result = Array.isArray(obj) ? [] : obj.constructor ? new obj.constructor() : {};
  for (const key in obj) {
    result[key] = deepClone(obj[key]);
  }
  return result;
}

/**
 * 深拷贝(数组)
 * @param array
 * @returns {*[]}
 */
export function deepCloneArray(array) {
  // 根据obj的类型判断是新建一个数组还是一个对象
  let newObj = Array.isArray(array) ? [] : {};
  // 遍历obj,并且判断是obj的属性才拷贝
  for (let key in array) {
    if (array.hasOwnProperty(key)) {
      newObj[key] = array[key];
    }
  }
  return newObj;
}

/**
 * 复制
 * @param copyValue 复制内容
 * @returns {Promise<unknown>}
 */
export function copyInfo(copyValue) {
  return new Promise((resolve) => {
    const copyUrl = document.createElement('input'); // 创建一个input框获取需要复制的文本内容
    copyUrl.value = copyValue;
    const appDiv = document.getElementById('app');
    appDiv.appendChild(copyUrl);
    copyUrl.select();
    document.execCommand('copy');
    copyUrl.remove();
    this.$message.success('复制成功');
    resolve(true);
  });
}

/**
 * 返回id集合字符串（id-1,id-2,id-3）
 * @param rows 集合
 * @returns {string}
 */
export function getIdsStr(rows) {
  let ids = '';
  rows.forEach(row => {
    ids += row.id + ',';
  });
  return ids.substring(0, ids.length - 1);
}

/**
 * 随机数范围
 * @param min 最小值
 * @param max 最大值
 * @returns {null|number}
 */
export function random(min, max) {
  if (arguments.length === 2) {
    return Math.floor(min + Math.random() * ((max + 1) - min));
  } else {
    return null;
  }
}

/**
 * 求两个集合的交集
 * @param a
 * @param b
 * @returns {Uint8Array | BigInt64Array | any[] | Float64Array | Int8Array | Float32Array | Int32Array | Uint32Array | Uint8ClampedArray | BigUint64Array | Int16Array | Uint16Array}
 */
export function intersect(a, b) {
  const that = this;
  a = this.unique(a);
  return this.map(a, function(o) {
    return that.contains(b, o) ? o : null;
  });
}

/**
 * 去除空格
 * @param str
 * @param type 1-所有空格 2-前后空格 3-前空格 4-后空格
 * @returns {*|void|string}
 */
export function trim(str, type) {
  type = type || 1;
  switch (type) {
    case 1:
      return str.replace(/\s+/g, '');
    case 2:
      return str.replace(/(^\s*)|(\s*$)/g, '');
    case 3:
      return str.replace(/(^\s*)/g, '');
    case 4:
      return str.replace(/(\s*$)/g, '');
    default:
      return str;
  }
}

/**
 * 在字符串中插入新字符串
 * @param soure 源字符串
 * @param index 插入坐标
 * @param newStr  新字符串
 * @returns {*}
 */
export function insertStr(soure, index, newStr) {
  const str = soure.slice(0, index) + newStr + soure.slice(index);
  return str;
}

/**
 * 滚动到顶部
 */
export function scrollToTop() {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
}

/**
 * 去除html标签
 * @param str
 * @returns {*|void|string}
 */
export function removeHtmlTag(str) {
  return str.replace(/<[^>]+>/g, '');
}

/**
 * 动态引入JS
 * @param src JS位置
 */
export function injectScript(src) {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = src;
  const t = document.getElementsByTagName('script')[0];
  t.parentNode.insertBefore(s, t);
}

/**
 * iphone是否有安全区
 */
export function getIphoneSafeArea() {
  let events = navigator.userAgent;
  if (events.indexOf('Android') > -1 || events.indexOf('Linux') > -1 || events.indexOf('Adr') > -1) {
    return false;
  } else if (events.toLowerCase().indexOf('iphone') > -1) {
    // iPhone X/iPhone XS/iPhone 11/iPhone 12
    if (screen.height === 812 && screen.width === 375) {
      return true;
    } else if (screen.height === 896 && screen.width === 414) { // iPhone XS/iPhone XR/iPhone 11/
      return true;
    } else if (screen.height === 844 && screen.width === 390) { // iPhone 12/iPhone 12 Pro
      return true;
    } else if (screen.height === 926 && screen.width === 428) { // iPhone 12 Pro Max
      return true;
    } else {
      return false;
    }
  } else if (events.indexOf('Windows Phone') > -1) {
    return false;
  } else if (events.indexOf('iPad') > -1) {
    return false;
  }
}

/**
 * 生成guid
 * @returns {string}
 */
export function getGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
    return v.toString(16);
  });
}

/**
 * 获取地址栏参数（转小写）
 * @param name
 * @returns {string|null}
 */
export function getQueryStringLower(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  let r = decodeURI(window.location.search.substr(1)).toLowerCase().match(reg);
  if (r != null)return unescape(r[2]);
  return null;
}

/**
 * 获取是否iPad
 * @returns {boolean}
 */
export function getIsPad(){
  let ua = navigator.userAgent;
  let isSafari = ua.indexOf("Safari") != -1 && ua.indexOf("Version") != -1;
  let isIphone = ua.indexOf("iPhone") != -1 && ua.indexOf("Version") != -1
  let isIPad = isSafari && !isIphone && 'ontouchend' in document;
  return /ipad/i.test(navigator.userAgent.toLowerCase()) && isIPad;
}

/**
 * 获取是否PC
 * @returns {boolean}
 */
export function getIsPC() {
  let userAgentInfo = navigator.userAgent;
  let Agents = ['Android', 'iPhone',
    'SymbianOS', 'Windows Phone',
    'iPad', 'iPod'
  ];
  let flag = true;
  for (let i = 0; i < Agents.length; i++) {
    if (userAgentInfo.indexOf(Agents[i]) !== -1) {
      flag = false;
      break;
    }
  }
  return flag;
}

/**
 * 检查字符串类型
 * @param str 字符串
 * @param type 验证类型
 * @returns {boolean}
 */
export function checkStr (str, type) {
  switch (type) {
    case 'phone':   //手机号码
      return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(str);
    case 'tel':     //座机
      return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
    case 'card':    //身份证
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str);
    case 'pwd':     //密码以字母开头，长度在6~18之间，只能包含字母、数字和下划线
      // return /^[a-zA-Z]\w{5,17}$/.test(str);
      return /^[a-zA-Z0-9~!@#\$%\^\&*\)\(+=._-]{6,18}$/.test(str)
    case 'postal':  //邮政编码
      return /[1-9]\d{5}(?!\d)/.test(str);
    case 'QQ':      //QQ号
      return /^[1-9][0-9]{4,9}$/.test(str);
    case 'email':   //邮箱
      return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str);
    case 'money':   //金额(小数点2位)
      return /^\d*(?:\.\d{0,2})?$/.test(str);
    case 'URL':     //网址
      return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(str)
    case 'IP':      //IP
      return /((?:(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d))/.test(str);
    case 'date':    //日期时间
      return /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2})(?:\:\d{2}|:(\d{2}):(\d{2}))$/.test(str) || /^(\d{4})\-(\d{2})\-(\d{2})$/.test(str)
    case 'number':  //数字
      return /^[0-9]$/.test(str);
    case 'english': //英文
      return /^[a-zA-Z]+$/.test(str);
    case 'chinese': //中文
      return /^[\\u4E00-\\u9FA5]+$/.test(str);
    case 'lower':   //小写
      return /^[a-z]+$/.test(str);
    case 'upper':   //大写
      return /^[A-Z]+$/.test(str);
    case 'HTML':    //HTML标记
      return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str);
    default:
      return true;
  }
}

/**
 * 密码验证（只包含数字和字母）
 * @param str
 * @returns {boolean}
 */
export function pwdLetterNumberVerify(str) {
  let pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？_]");
  let letterDigitReg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]{8,16}$/);
  if(pattern.test(str)) {
    return false;
  } else if (!letterDigitReg.test(str)) {
    return false;
  }
  return true;
}

/**
 * 格式化富文本内容
 * @param str
 * @returns {*}
 */
export function getFormatRichText(str) {
  str=str.replace('wx_fmt=gif','');
  str=str.replace('wx_fmt=jpeg','');
  str=str.replace('wx_fmt=png','');
  let reg = new RegExp("<video","g");//g,表示全部替换
  str=str.replace(reg,"<video style='max-width:100% !important;'");
  let regImg = new RegExp("<img","g");//g,表示全部替换
  str=str.replace(regImg,"<img style='max-width:100% !important;'")
  let regTable = new RegExp("<table","g");//g,表示全部替换
  str=str.replace(regTable,"<table style='max-width:100% !important;'")
  let regUl = new RegExp("<ul","g");//g,表示全部替换
  str=str.replace(regUl,"<ul class='html-ul'");
  let regOl = new RegExp("<ol","g");//g,表示全部替换
  str=str.replace(regOl,"<ol class='html-ol'");
  return str;
}

/**
 * 清除两端符号
 * @param str 目标字符串
 * @param symbol 符号类型: 1.comma-逗号 | 2.stop-顿号
 * @returns {boolean}
 */
export function clearBothSymbol(str,symbol) {
  let rep = '';
  switch (symbol) {
    case 'comma':   //逗号
      rep = /，$/gi;
      break;
    case 'stop':   //顿号
      rep = /、$/gi;
      break;
    default:
      return true
  }
  return str.replace(rep, "");
}

/**
 * 标准时间格式化
 * @param date
 * @param fmt
 * @returns {string|*}
 */
export function standardTimeFormatDate(date, fmt) {
  if(!date){
    return '';
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}
function padLeftZero(n){
  return n < 10 ? '0' + n : n
}

/**
 * @description: 裁剪图片压缩
 */
export function compressImg(file) {
  let disposeFile = file;
  const read = new FileReader();
  const fileSize = parseFloat(parseInt(disposeFile.size) / 1024 / 1024).toFixed(2);
  return new Promise((resolve, reject) => {
    try {
      read.readAsDataURL(disposeFile);
      read.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          let scale = 1;
          if(fileSize >= 0.2 && fileSize < 0.6) {
            scale = 1.5;
          } else if(fileSize >= 0.6) {
            scale = 2;
          }
          let w = Math.floor(this.width / scale),
              h = Math.floor(this.height / scale);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let base64 = canvas.toDataURL(disposeFile.type, 1);
          canvas.setAttribute('width', w);
          canvas.setAttribute('height', h);
          ctx.drawImage(this, 0, 0, w, h);
          console.log(fileSize)
          // 小于200KB不压缩
          if (fileSize >= 0.2 && fileSize < 0.6) {
            base64 = canvas.toDataURL(disposeFile.type, 0.8);
          } else if(fileSize >= 0.6) {
            base64 = canvas.toDataURL(disposeFile.type, 0.5);
          } else {
            base64 = canvas.toDataURL(disposeFile.type, 1);
          }
          let objFile = dataURLtoFile(base64, disposeFile.name);
          resolve({file: objFile, base64Url: base64});
        };
      };
    } catch (error) {
      console.log(error)
      reject(disposeFile);
    }
  });
}

/**
 * @description: 图片压缩
 */
export function uploaderCompressImg(file) {
  let disposeFile = file;
  console.log(disposeFile.size)
  const read = new FileReader();
  const fileSize = parseFloat(parseInt(disposeFile.size) / 1024 / 1024).toFixed(2);
  return new Promise((resolve, reject) => {
    try {
      read.readAsDataURL(disposeFile);
      read.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          let scale = 1;
          if(fileSize <= 1) {
            scale = 1.5;
          } else if(fileSize > 1 && fileSize <= 3) {
            scale = 2;
          } else if(fileSize > 3 && fileSize <= 5) {
            scale = 2.5;
          }
          let w = Math.floor(this.width / scale),
              h = Math.floor(this.height / scale);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let base64 = canvas.toDataURL(disposeFile.type, 1);
          canvas.setAttribute('width', w);
          canvas.setAttribute('height', h);
          ctx.drawImage(this, 0, 0, w, h);
          // 小于1MB不压缩
          if (fileSize <= 1) {
            base64 = canvas.toDataURL(disposeFile.type, 0.8);
          } else if(fileSize > 1 && fileSize <= 3) {
            base64 = canvas.toDataURL(disposeFile.type, 0.5);
          } else if(fileSize > 3 && fileSize <= 5) {
            base64 = canvas.toDataURL(disposeFile.type, 0.45);
          } else {
            base64 = canvas.toDataURL(disposeFile.type, 1);
          }
          let objFile = dataURLtoFile(base64, disposeFile.name);
          console.log(parseInt(objFile.size) / 1024 / 1024)
          resolve({file: objFile, base64Url: base64});
        };
      };
    } catch (error) {
      console.log(error)
      reject(disposeFile);
    }
  });
}

/**
 * @description: 将base64编码转回file文件
 */
function dataURLtoFile(dataUrl, fileName) {
  let arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bStr = atob(arr[1]),
      n = bStr.length,
      u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bStr.charCodeAt(n);
  }
  return new File([u8arr], fileName, {
    type: mime,
  });
}

//函数
export async function isWx() {
  return new Promise(resolve => {
    if (window.__wxjs_environment === 'miniprogram') {
      window.wx.miniProgram.getEnv(res => {
        if(res.miniprogram) resolve("mini-wx")
      });
    } else {
      const ua = window.navigator.userAgent;
      if(ua.match(/MicroMessenger/i) == 'MicroMessenger'){
        resolve("wx");
      } else {
        resolve("no-wx");
      }
    }
  });
  // if (window.__wxjs_environment === 'miniprogram') {
  //   return new Promise(resolve => {
  //     alert(JSON.stringify(window.wx));
  //     window.wx.miniProgram.getEnv(function (res) {
  //       alert(JSON.stringify(res));
  //       if (res.miniprogram) {
  //         resolve("mini-wx");
  //       } else {
  //         resolve("wx");
  //       }
  //     });
  //   });
  // } else {
  //   console.log("不在小程序");
  //   return new Promise(resolve => {
  //     resolve("no-wx");
  //   });
  // }
}
function getMiniProgram() {
  return new Promise(resolve => {
    if (window.__wxjs_environment === 'miniprogram') {
      window.wx.miniProgram.getEnv(res => {
        resolve(res.miniprogram)
      });
    } else {
      resolve(false);
    }
  });
}

/**
 * 获取是否PC
 * @returns {boolean}
 */
export const isPC = () => {
  let userAgentInfo = navigator.userAgent;
  let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let flag = true;
  for (let i = 0; i < Agents.length; i++) {
    if (userAgentInfo.indexOf(Agents[i]) !== -1) {
      flag = false;
      break;
    }
  }
  if(userAgentInfo.toLowerCase().indexOf("micromessenger")>=0) {
    flag = false;
  }
  return flag;
}

/**
 * 动态加载js文件
 */
export function loadJS(src, isCache = true) {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    let head = document.head;
    script.type = 'text/JavaScript';
    if (isCache) {
      script.src = src;
    } else {
      script.src = src + "?t=" + new Date().getTime();
    }
    if (script.addEventListener) {
      script.addEventListener('load', resolve, false);
      script.addEventListener('error', reject, false);
    }
    head.appendChild(script);
  });
}
