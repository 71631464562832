<template>
  <div class="custom-nav-bar-fill">
    <div class="custom-nav-bar">
      <div class="left">
        <template v-if="isClose">
          <custom-image class="touch" :height="54" :width="54"
                        :src="state.iconGoBack" @click="handleClose"></custom-image>
        </template>
        <template v-else>
          <!--回首页-->
          <template v-if="isGoHome">
            <custom-image class="touch" :height="54" :width="54"
                          :src="state.iconGoHome" @click="handleHome"></custom-image>
          </template>
          <!--返回-->
          <template v-else>
            <custom-image class="touch" :height="54" :width="54"
                          :src="state.iconGoBack" @click="handleGoBack"></custom-image>
          </template>
        </template>
      </div>
      <div class="center ellipsis">{{title}}</div>
      <div class="right" :class="{'w-auto': isRightWidth}">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CustomImage from "@/components/custom/CustomImage";
export default {
  name: "CustomNavBar",
  components: {
    CustomImage
  },
  props: {
    link: {
      type: String,
      default: null
    },
    redirect: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    customTitle: {
      type: Boolean,
      default: false
    },
    isClose: {
      type: Boolean,
      default: false
    },
    customBack: {
      type: Boolean,
      default: false
    },
    isGoHome: {
      type: Boolean,
      default: false
    },
    isRightWidth: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      iconGoBack: require('@/assets/images/icons/icon_go_back.png'),
      iconGoHome: require('@/assets/images/icons/icon_go_home.png'),
      formPagePath: computed(()=> store.state.system.formPagePath)
    });

    //回首页
    const handleHome = ()=> {
      router.replace({ name: 'Home' });
    }
    //返回
    const handleGoBack = () => {
      if(props.customBack) {
        emit('go-back', true);
        return;
      }
      if(props.link) {
        router.replace({ name: props.link });
        if(props.redirect) {
          router.replace({ name: props.link, redirect: props.redirect || '/' });
        }
      } else {
        if(state.formPagePath === '/') {
          router.go(-1);
        } else {
          router.replace({ path: state.formPagePath });
        }
      }
    }

    //关闭
    const handleClose = () => {
      emit('close', true);
    }
    return {
      state,
      handleGoBack,
      handleClose,
      handleHome
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-nav-bar-fill {
  position: relative;
  height: 80px;
  z-index: 10;
}
.custom-nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  & .left {
    position: absolute;
    left: 44px;
    top: 13px;
    width: 74px;
  }
  & .center {
    position: absolute;
    top: 0;
    left: 150px;
    right: 150px;
    text-align: center;
    font-size: 30px;
    line-height: 80px;
    margin: 0 auto;
    color: var(--header-sub-title);
  }
  & .right {
    position: absolute;
    right: 54px;
    top: 13px;
    width: 74px;
    color: #FFFFFF;
    &.w-auto {
      width: auto;
      top: 0;
      line-height: 80px;
    }
  }
}
</style>
