export default {
	common: {
		loadingText: 'Loading',
		loadFailed: 'Failed',
		cancelBtnText: 'Cancel',
		submitBtnText: 'Submit',
		sureBtnText: 'OK',
		sendBtnText: 'Send',
		loginBtnText: "To login",
		sendSucceededMsg: 'Success',
		sendEmailMsg: 'Send to Email succeeded',
		unlimitedSelect: 'Does not matter',
		age: 'years old',
		emailOrPhone: 'Email/Telephone',
		emailOrPhonePlaceholder: 'Email/Telephone',
		passwordLabel: 'Password',
		passwordPlaceholder: 'Password',
		passwordEmptyTip: 'Please input password',
		passwordFormatTips: "Password must contain both number and letter, password length between 8~16 characters.",
		forgetPassword: 'Forget password',
		confirmPasswordLabel: 'Confirm password',
		confirmPasswordPlaceholder: 'Password again to confirm',
		confirmPasswordTip: 'Please input your password again to confirm',
		confirmPasswordFormatTips: "Password must contain both number and letter, password length between 8~16 characters.",
		passwordDiffer: 'The two passwords you entered does not match',
		companyEmptyTip: 'Please input company name',
		companyLengthTip: 'Company name too short',
		companySelectEmptyTip:"Please select company",
		personalCenter: 'Personal Center',
		moreText: 'View more',
		contactUs: 'Contact us',
		messages: {
			success: 'succeeded',
			saveSuccess: "Saved",
			uploadSuccess: "Uploaded",
			deleteSuccess: "Deleted",
			submitSuccess: "Submitted",
			submitFailed: "Submit failed",
			modifySuccess: "Edit succeeded"
		},
		messageBoxTitle: "Hint",
		confirmButtonText: 'Confirm',
		cancelButtonText: 'Cancel',
		gotItButtonText: 'OK',
		toImproveBtnText: "To complete",
		toAuditBtnText: "To review",
		imgTip: 'Only support jpg、png format',
		videoTip: 'Only support mp4、mov format',
		selectProvinceAndCity: 'Select province and city(China only)',
		selectCountry: 'Select country',
		btnSaveText: 'Save',
		emailLabel: 'Email',
		emailPlaceholder: 'Email',
		emailEmptyTip: "Please input Email",
		emailCodePlaceholder: 'Email verification code',
		emailCodeEmptyTip: "Please input verification code sent to your Email",
		emailErrorTip: 'Email address not valid',
		phoneLabel: 'Telephone',
		phonePlaceholder: 'Telephone',
		phoneEmptyTips: 'Please input telephone',
		phoneErrorTip: 'Only support telephone number in mainland China',
		imgVerificationTip: 'Please input the verification code',
		imgCodeEmptyTip: 'Verification code is empty',
		imgCodeSuccess: 'Verification code Sent',
		messageCodeLabel: 'Verification code',
		messageCode: 'Send code',
		messageErrorPlaceholder: 'Verification code',
		messageErrorCode: 'Please input verification code',
		emailPhoneEmptyTip: "Please input Email/telephone",
		incorrectEmailPhoneFormat: "telephone or Email not valid",
		modifyBindingBtnText: "Edit binding",
		confirmBindingBtnText: "Confirm binding",
		collection: 'Collection',
		collectionAlready: 'Collected',
		download: 'Download',
		nationwide:"China",
		all:"All",
		languageSelect:{
			Chinese:"Chinese",
			German:"German",
			English:"English",
			// Cantonese:"Cantonese",
			others:"Others",
			unlimited:"Does not matter"
		},
		loginTip:"Please login first",
		uploadingText: "Uploading",
		submittingText: "Submitting",
		emptyText: "No data",
		comingSoonPleaseWait: "Coming soon",
		deleteBtnText: "Delete",
		editBtnText: "Edit",
		to: "to",
		cropperDialogTitle: "Crop photo",
		upToNow: "Now",
		pleaseLoginFirst: "Please login first",
		videoPreviewDialogTitle: "Video preview",
		picPreviewDialogTitle: "Photo preview",
		userNotExistTips: "User does not exist",
		currentUserNotPasswordTips: "You haven't set your password yet, please set your password.",
		oldUserSetPasswordTips: "Due to system upgrade, please reset your password for security concern.",
		incorrectPasswordTips: "password is wrong, please re-enter or reset your password.",
		completeResumeTips: "You haven't renew your resume in rencent year, we recommend you renew your resume first.",
		oneClickLoginBindingMobileTips: "Would you like to bind your telephone number to enable one-click login?",
		systemError: "System busy, please try later.",
		changeOne: "Change",
		selectPlaceholder: "Please select",
		customBtnText: "Customize",
		onlyJobSeekersTips: "This service is currently only available to job seekers."
	},
	picker: {
		proficiencyLang: {
			title: "Please select language skill"
		},
		salary: {
			title: "Please select salary range"
		},
		internship: {
			title: "Please select Full-time/Intern"
		},
		jobStatus: {
			title: "Please select active status"
		},
		positionCategory: {
			title: "Please select job category",
		},
		yearMonth: {
			title: "Please select year/month",
			yearText: "year",
			monthText: "month",
			dayText: "day",
			startTimePlaceholder: "Starting date",
			endTimePlaceholder: "Ending date",
			startTimeEmptyTips: "Please input starting date",
			endTimeEmptyTips: "Please input ending date",
			validTimeTips: "Ending date must be larger than starting date"
		},
		business: {
			title: "Choose Company",
			selectPlaceholder: "Company",
			emptyTips: "Choose Company"
		},
		positionIndustry: {
			title: "Choose Industry",
  		selectPlaceholder: "Please Select",
  		emptyTips: "Please Make a Selection",
		},
		careerGuidanceTime: {
			title: "Choose Time",
			selectPlaceholder: "Please Select",
  		emptyTips: "Please Make a Selection",
		},
		careerGuidanceAddress: {
			title: "Please select an address",
			selectPlaceholder: "Please Select",
  		emptyTips: "Please Make a Selection",
		},
		careerGuidanceType: {
			title: "Select Online/Offline",
			selectPlaceholder: "Please Select",
  		emptyTips: "Please Make a Selection",
		}
	},
	uploadMessage: {
		uploading: "Uploading",
		uploadSucceeded: "Uploaded",
		uploadFailed: "Upload failed"
	},
	workYearsSelect: {
		select1: 'less than 1 year',
		select1to3: '1-2 years ',
		select3to5: '3-5 years ',
		select5to10: '5-10 years ',
		select10: 'more than 10 years',
	},
	diplomaSelect: {
		highSchool: 'High School',
		juniorCollege: 'Associate BS',
		undergraduate: 'BS/BA',
		masterDegree: 'MS/MBA',
		doctor: 'PhD',
	},
	jobStatusSelect: {
		unknown: "unknown",
		onDutyWithinAMonth: " Employed-active within one month",
		consideringOpportunities: "Employed-consider opportunity",
		resignation: "Actively looking for job",
		students: "Students, looking for internship"
	},
	applyMentorStatusValues: {
		notApplied: "Not applied",
		applying: "Applied",
		pass: "Accepted",
		reject: "Rejected"
	},
	resumeStatusValues: {
		notSubmitted: "Not submitted",
		toBeApproved: "Pending",
		published: "Published",
		reject: "Rejected",
		disable: "Disabled"
	},
	positionStatusValues: {
		draft: 'Draft',
		beingApproved: 'Pending',
		publish: 'Publish',
		reject: 'Rejected',
		disable: 'Disabled',
		offline: 'Unpublish',
		delete: 'Deleted',
		expired: 'Expired'
	},
	sexValues: {
		male: 'male',
		female: 'female',
		other: 'other',
		unknown: 'unknown'
	},
	privilegeValues: {
		topPosts: "Top listed Job",
		downloadResume: "Download resume",
		popupTheScreenOnTheHomePage: "MiniApp popup",
		publishTheCarouselMap: "Homepage KV",
		logoWallExposed: "Partner",
		popularCompanyDisplay: 'Company placement on home page & Top listing on the page "Companies"',
		postPosition: "Post job",
		expiryDateLabel: "Due Time",
		prohibitUsePrivilege: "Sorry, you can not use this premium service",
		emptyListTips: "Premium service not available."
	},
	salaryValues: {
		salaryBelow10K: "Below 10K",
		salary10To15K: "10-15K",
		salary15To25K: "15-25K",
		salary25To35K: "25-35K",
		salaryAbove35K: "Above 35K"
	},
	proficiencyLangValues: {
		notApplied: "Not applied",
		basicCommunication: "Basic communication",
		familiarCommunication: "Familiar communication",
		proficientCommunication: "Proficient communication",
		nativeSpeaker: "Native speaker",
	},
	openClassTypeValues: {
		online: 'Online',
		offline: 'Offline'
	},
	careerCoachingTypeValues: {
		online: 'Online',
		offline: 'Offline'
	},
	openClassLanguageValues: {
		CN: 'CN',
		EN: 'EN',
		GER: 'GER',
		CNtoEN: 'CN&EN',
		CNtoGER: 'CN&GER'
	},
	openClassTimeStatusValues: {
		currentAndUpcoming: 'Current',
		past: "Past"
	},
	login: {
		loginText: 'Log in',
		logoutText: 'Logout',
		otherLoginTitle: 'Personal user/employee login',
		enterpriseLoginTitle:"Employer login",
		notLoginTips: "Please login first",
		resetPasswordBtnText: 'Reset password',
		ruleTip: 'Please agree with Term & Condition',
		agreementDesc: {
			part1: "I have read and agreed with ",
			part2: "Terms & Conditions",
			part3: ""
		},
		toSetBtnText: "To settings",
		unbindBtnText: "To bind",
		toResetBtnText: "To reset",
		registerNowBtnText: "Register now",
		customerRegisterBtnText: "No account/Sign up",
		exitMessageTips: "Are you sure to logout？"
	},
	termsConditions: {
		pageTitle: "Terms and Conditions"
	},
	privacyPolicy: {
		pageTitle: "Privacy Policy"
	},
	paging: {
		finishedDesc: "This is the end"
	},
	home: {
		contactBtnTxt: 'Contact',
		navList: {
			index: 'Home',
			jobs: 'Jobs',
			recruit: 'Talent Pool',
			train: 'Learning',
			profile: 'Profile'
		},
		loginText: 'Login/Sign up',
		cn: '中文',
		en: 'Eng',
		tagList: {
			apply: "Full-time Job",
			practice: "Intern Job",
			company: "Company",
			video: "Job Video",
			recruit: "Talent Pool",
			news: "HR Insight",
			club: "German HR Club",
			deSchool: "Learning Hub",
			openClass: "Public Training",
			internalTraining: "In-house Program",
			careerGuidance: "Career Coaching",
			evaluationTools: "Assessment Tool",
			"talentForum": "Talent Forum"
		},
		positionText: 'Hot Jobs',
		newsText: 'Latest News',
		searchPlaceholder: 'Job Keyword',
		searchBtnTxt: 'Search',
		focusText1: 'Welcome to follow',
		focusText2: 'Our official accounts',
		cooperation: 'Partners',
		sponsor: 'Sponsors',
		resumeText:"Send resume",
		onlineResumeTitle: "Online CV",
		onlineResumeDesc: "Join our talent pool",
		onlineResumeBtnTxt: "Create a profile",
		applyMentorTitle: "Career Mentor",
		applyMentorDesc: "Become a career mentor",
		applyMentorBtnTxt: "Apply now",
		viewCompany: 'Company List'
	},
	resume: {
		pageManageTitle: 'Resume edit',
		searchPlaceholder: 'Job Title',
		searchBtnTxt: 'Search',
		manageTag: {
			tag1: 'My collection',
			tag2: 'Received resume ',
		},
		sendInterviewState: 'Interview invitation sent',
		listEmptyText: 'No qualified resume found',
		relevantWorkExperienceDesc: 'Experience',
		diplomaPlaceholder: 'Education',
		workYearsPlaceholder: 'Experience',
		positionCategoryPlaceholder: 'Job title',
		diplomaPickerTitle: 'Please select degree',
		workYearsPickerTitle: 'Select working years of experience',
		positionCategoryPickerTitle: 'Select job intent',
		positionTitleLabel: 'Apply job',
		intentionLabel: 'Job intention',
		workYearsDesc: 'Detail description',
		educationLabel: 'Education experience',
		workExperienceLabel: 'Experience',
		jobStatusLabel: 'Active status',
		proficiencyLangeLabel: 'Language skill',
		jobSkillsLabel: 'Skills',
		resumeDetailsPageTitle: 'Resume',
		downloadResumeBtnText: "Download resume"
	},
	sendInterview: {
		pageTitle: 'Interview invitation',
		pageDesc: 'Interview info will not be able to edit after sending',
		pageSubTitle: 'Interview info',
		sendInterviewBtnTxt: "Send interview invitation",
		companyLabel: 'Company',
		positionLabel: 'Job title',
		provinceCityLabel: 'Province/City',
		provinceCityPlaceholder: 'Province/city',
		interviewTimeLabel: 'Interview time',
		interviewTimePlaceholder: 'Time',
		addressLabel: 'Interview address',
		addressPlaceholder: 'Address',
		mattersLabel: 'Notice',
		mattersPlaceholder: 'Please do not enter wechat or QQ account',
		interviewTimeMsg: 'Please select interview time',
		addressMsg: 'Please input interview address',
		mattersMsg: 'Please input notice',
		rejectBtnText: "Rejected",
		acceptBtnText: "Accept",
		pickerTitle: "Select time",
		rejectedText: "Rejected",
		acceptedText: "Accepted"
	},
	messages: {
		pageTitle: 'Message',
		read: "Read",
		tagList: {
			all: 'All',
			unread: 'Unread',
			resume: 'Resume',
			interview: 'Interview',
			system: 'System',
		},
		messageTypeValues: {
			system: 'System',
			resume: 'Resume',
			interview: 'Interview',
		},
		noData: "No message"
	},
	register: {
		enterprisePageTitle: 'Employer register',
		candidatePageTitle: 'Job seeker register',
		newBusinessLabel: 'New company to DE Talents',
		radioYes: 'Yes',
		radioNo: 'No',
		nameLabel: 'Company Name',
		namePlaceholder: 'Company name',
		nameTip: 'Please input company name',
		selectBusiness: 'Choose Company',
		registerBtn: 'Sign up'
	},
	resetPassword: {
		pageTitle: 'Reset password',
		btnEditText: 'Reset',
	},
	positionList: {
		salary: 'Salary',
		position: 'Jobs',
		isInternship: 'Full-time/Internship',
		company: 'Company',
		internshipSelect: {
			internship: 'Internship',
			formalPosition: 'Full-time',
		},
		emptyTip: 'No qualified job found',
		positionAll: "All jobs",
		interview: 'Negotiable',
		careerFairFlag: "Career Fair"
	},
	positionDetail: {
		pageTitle: "Job List",
		apply: 'Apply/Send Resume',
		title: 'Job Description',
		description: 'Job Info',
		requirement: 'Job Requirement',
		postMessage1:"Send succeeded",
		postMessage2:"No need",
		postMessage3:'No need',
		postMessage4:"Your have applied this job earlier, please wait for further contact, good luck~",
		interview: 'Negotiable',
		publishTimeLabel: "Post time",
		readingVolumeLabel: "Viewed"
	},
	companyList: {
		pageTitle: "Companies",
		searchPlaceholder: "Company",
		emptyTips: "No company found",
		viewDetails: "View detail",
	},
	companyDetail: {
		pageTitle: "Company Info",
		companyProfileTitle: "Company Introduction",
		positionUnderRecruitmentTitle: "Open jobs",
		positionUnderRecruitmentEmptyDesc: "No jobs available",
		interview: 'Negotiable',
		viewAllBtnText: "View all",
		stowBtnText: "Hide"
	},
	news:{
		pageTitle:"Latest News",
		backTitle:"News List",
		noData: "Coming soon"
	},
	myResume: {
		pageTitle: "My Resume",
		userInfoTitle: "Personal Info",
		intentionTitle: 'Preference job',
		improveUserInfoTitle: "Manage personal info",
		improveMyResumeTitle: "Manage resume",
		uploadResumeFile: "Upload resume attachment",
		reUploadResumeFile: "Re-upload resume attachment",
		uploaded: "Uploaded",
		experienceTitle: "Experience",
		experienceOldTitle: "Experience(transfer)",
		toAddExperienceEmpty: "Add working experience",
		educationTitle: "Education",
		educationOldTitle: "Education(transfer)",
		toAddEducationEmpty: "Add Education experience",
		proficiencyLangeTitle: "Language",
		skillTitle: "Skills",
		haveSkillsTitle: "My skills",
		toAddSkillEmpty: "Add skills",
		personalSummaryTitle: "Career Advantage",
		resumeUploadTitle: "Attachment",
		resumeEditBtnText: "Edit resume",
		completeBtnText: "Complete",
		workDescLabel: "Description",
		clearOldDataTips: "This content come from legacy system, deletion will be permenant, are you sure to delete it?",
		clearSuccessTips: "Delete complete",
		personalSummaryPlaceholder: "Career advantage",
		personalSummaryEmpty: "Please input your career Advantage",
		resumeFileEmptyDesc: "No content",
		experienceEmptyDesc: "No content",
		educationEmptyDesc: "No content",
		proficiencyLangEmptyDesc: "No content",
		skillEmptyDesc: "No content",
		personalSummaryEmptyDesc: "No content",
		uploadFileDesc: {
			part1: 'Support PDF format or image(jpg,png)',
			part2: 'File size no more than 5M'
		},
		uploadFileType: "Only support PDF format",
		uploadFileSize: "File size no more than 5M",
		uploadFileDeleteDesc: "Are you sure to delete this attachment?",
		proficiencyLangOtherPlaceholder: "Content",
		skillNamePlaceholder: "Add custumized skills",
		skillMaxNumberTips: {
			part1: 'You can only add up to',
			part2: 'skills'
		},
		skillNameEmptyTips: "Please input customize skill",
		skillNameFontNumberTips: "No more than 10 characters",
		skillSelectEmptyTips: "Please select sill",
		positionCategoryPageTitle: "Preference job",
		positionCategoryPlaceholder: "Preference job",
		positionCategoryEmptyTips: "Please select preference job",
		positionCategoryPlaceholderPicker: "Job intention",
		positionCategoryMaxNumberTips: {
			part1: 'You can only add up to ',
			part2: ' job intentions'
		},
		selectedTips: "Selected",
		education: {
			addPageTitle: "Add education experience",
			editPageTitle: "Edit education experience",
			deleteDataTips: "Deletion will be permenant, are you sure to delete it",
			nameLabel: "School",
			namePlaceholder: "School",
			nameEmptyTips:"Please input school",
			diplomaLabel: "Education",
			diplomaPlaceholder: "Degree",
			diplomaEmptyTips: "Please select degree",
			diplomaPickerTitle: "Please select degree",
			majorLabel: "Field of study",
			majorPlaceholder: "Field of study",
			majorEmptyTips: "Please input field of study",
			timeLabel: "Study duration",
			timeEmptyTips: "Select duration"
		},
		experience: {
			addPageTitle: "Add working experience",
			editPageTitle: "Edit working experience",
			deleteDataTips: "Deletion will be permenant, are you sure to delete it",
			companyNameLabel: "Company name",
			companyNamePlaceholder: "Company name",
			companyNameEmptyTips:"Please input company name",
			companyLengthTip: "Company name too short",
			positionNameLabel: "Position",
			positionNamePlaceholder: "Position",
			positionNameEmptyTips:"Please input position",
			performanceLabel: "Description",
			performancePlaceholder: "Description",
			performanceEmptyTips:"Please input description",
			timeLabel: "Work duration",
			timeEmptyTips: "Select work duration"
		},
		userInfo: {
			avatarBtnText: "Upload photo",
			userEmailLabel: "Email",
			modifyEmailBtnTxt: "Edit Email",
			improveEmailBtnTxt: "Add Email",
			improveEmailTitle: "Add Email",
			modifyEmailTitle: "Edit email",
			lastNameLabel: "Last name",
			lastNamePlaceholder: "Last name",
			lastNameEmptyTips: "Please input last name",
			firstNameLabel: "First name",
			firstNamePlaceholder: "First name",
			firstNameEmptyTips: "Please input first name",
			sexLabel: "Sex",
			sexPlaceholder: "Sex",
			sexEmptyTips: "Please select your sex",
			birthdayLabel: "Birthday",
			birthdayPlaceholder: "Birthday",
			birthdayEmptyTips: "Please choose your birthday",
			birthdayPickerTitle: "Select birth date",
			countryLabel: "Country",
			countryPlaceholder: "Country",
			countryEmptyTips: "Please select country",
			countryPickerTitle: "Select country",
			areaLabel: "Province & City",
			areaPlaceholder: "Province & city",
			areaEmptyTips: "Please select province & city",
			areaPickerTitle: "Select province/city",
			addressLabel: "Address",
			addressPlaceholder: "Address",
			addressEmptyTips: "Please input address",
			nativeLanguageLabel: "Native language",
			nativeLanguagePlaceholder: "Native language",
			nativeLanguageEmptyTips: "Please select native language",
			nativeLanguagePickerTitle: "Select native language",
			workYearsLabel: "Working Experience",
			workYearsPlaceholder: "Working experience",
			workYearsEmptyTips: "Please select working experience",
			workYearsPickerTitle: "Select years of experience",
			workAreaLabel: "Preference job location",
			workAreaPlaceholder: "Preference job location",
			workAreaEmptyTips: "Please select preference job location",
			workAreaPickerTitle: "Please select preferred location",
			jobStatusLabel: "Job status",
			jobStatusPlaceholder: "Job status",
			jobStatusEmptyTips: "Please select job status",
			emailLabel: "Email",
			emailPlaceholder: "Email",
			emailEmptyTips: "Please input email",
			verificationCodeTitle: "Security verification",
			verificationCodeLabel: "Send code",
			verificationCodePlaceholder: "Please input the verification code sent to your email",
			verificationCodeEmptyTips: "Please input the verification code sent to your email",
			resumeFileEmptyDesc: "No attachment",
			experienceEmptyDesc: "No content",
			educationEmptyDesc: "No content",
			proficiencyLangEmptyDesc: "No content",
			skillEmptyDesc: "No content",
			personalSummaryEmptyDesc: "No content"
		},
		proficiencyLange: {
			pageTitle: "Language skill",
			title: "Language skill",
			langeDesc: "How about your language competency in business environment？",
			chineseLabel: "Chinese/Mandarin",
			englishLabel: "English",
			germanLabel: "German",
			othersLabel: "Others",
			othersPleaseSpecifyLabel: "Others (please specify)",
			pleaseSelect: "Please select"
		}
	},
	applyMentor: {
		pageTitle: "Apply for career mentor",
		underReviewTips: "Career mentor qualification under review",
		approvedTips: "You've already become a career mentor",
		specialtiesLabel: "Expertise",
		specialtiesPlaceholder: "Content",
		specialtiesEmptyTips: "Please input your expertise",
		contributeResourcesLabel: "Resource you willing to contribute.",
		contributeResourcesPlaceholder: "Content",
		contributeResourcesEmptyTips: "Please input resource you willing to contribute",
		mediaLabel: "Photo(formal dress)",
		mediaEmptyTips: "Please upload photo",
		activityPhotoLabel: "Photo",
		activityPhotoEmptyTips: "Please upload photo",
		videoLabel: "Video",
		videoEmptyTips: "Please upload video",
		submitSuccess: "Submitted, please wait for review result.",
		upload: {
			onlyOneVideoFileTips: "You can only upload one video file.",
			uploadFileSizeTips: "Video size could not exceed 100MB"
		},
		submitApplicationBtnTxt: "Submit application",
		mediaUploadDesc: {
			part1: "Maximum image size: 3M"
		},
		activityPhotoUploadDesc: {
			part1: "Up to ",
			part2: " photoes, maximum photo size: 3M"
		},
		videoUploadDesc: {
			part1: "Upload activity video you have attended, Maximum video size: 100M."
		},
		tagTitle: "Career Mentor"
	},
	productList: {
		pageTitle: "Premium service list",
		detailsBtnText: "Detail",
		noData: 'No content'
	},
	recruit: {
		pageTitle: "Job Fair",
		participatingEnterprisesTitle: "Participating Companies",
		moreInformationTitle: "Introduction",
		positionInformationTitle: "Job List",
		positionNoData: "No job available"
	},
	myFavorites: {
		pageTitle: "My collections",
		noData: "No collections"
	},
	myApplicationRecord: {
		pageTitle: "Apply record",
		noData: "No apply record",
		applicationTime: "Apply time",
		interviewInvitationReceived: "Interview invitation received",
		posted: "Applied"
	},
	profileIndex: {
		messageTitle: 'My message',
		unread: 'Unread',
		orderTitle: "My Orders",
  	unpaid: "Unpaid",
	},
	profileBusiness: {
		businessDesc: "Login PC website to get full experience.",
		toolsDesc: {
			part1: "Job Management",
			part2: "Resume management",
			part3: "Other premium enterprise service"
		}
	},
	profileConsumer: {
		updateImproveBtnText: "Update personal info",
		recordTags: {
			applicationRecord: "Apply record",
			collectionRecord: "My record",
			trainingRecord: "My training",
			myCertificate: "My certifications"
		}
	},
	contactUs: {
		pageTitle: "Contact us"
	},
	employerBranding: {
		pageTitle: 'Employer Branding'
	},
	openClass: {
		title: "Public Training",
		timeStatusPickerTitle: 'Time',
		languagePickerTitle: "Language",
		typePickerTitle: "Online/Offline",
		emptyTips: "No Public Training Courses Available",
		free: "Free",
		vip: "Member",
		nonVip: "Non-Member",
		searchPlaceholder: "Search/Keyword",
	},
	monthView: {
		Jan: "Jan",
		Feb: "Feb",
		Mar: "Mar",
		Apr: "Apr",
		May: "May",
		Jun: "Jun",
		Jul: "Jul",
		Aug: "Aug",
		Sep: "Sep",
		Oct: "Oct",
		Nov: "Nov",
		Dec: "Dec"
	},
	careerGuidance: {
		title: "Career Coaching",
		detailsTitle: "Career Coaching Appointment Details",
		emptyTips: "No career coaching available now.",
		makeAnAppointmentBtnText: "Book Now",
		consultingFeeLabel: "Consulting Fee",
		mentorContentTitle: "Mentor Introduction",
		viewAllBtnText: "View all"
	},
	careerGuidanceForm: {
		nameLabel: "Name",
		contactInfoLabel: "Contact Info",
		industryLabel: "Industry",
		occupationLabel: "Professional Background",
		typeLabel: "Online/Offline",
		timeLabel: "Time",
		addressLabel: "Address",
		namePlaceholder: "Name",
		contactInfoPlaceholder: "Phone number",
		industryPlaceholder: "Industry",
		occupationPlaceholder: "Detailed background information",
		typePlaceholder: "Online/Offline",
		timePlaceholder: "Time",
		addressPlaceholder: "Address",
		nameMsg: "Please provide your name",
		contactInfoMsg: "Please provide your phone number",
		industryMsg: "Industry",
		typeMsg: "Online/Offline",
		timeMsg: "Time",
		addressMsg: "Address",
		repeatAppointmentTips: "You have already made an appointment with this mentor, are you sure you want to make another?",
	},
	certificate: {
		pageTitle: "My certificates",
		pageTitleEdit: "Edit certificate",
		pageTitleAdd: "Upload certificate",
		addBtnText: "Upload new certificate",
		emptyTips: "No certificates to display",
		deleteTips: "Are you sure you want to delete this certificate?",
	},
	certificateForm: {
		uploadAttachmentLabel: "Upload certificate",
		uploadAttachmentMsg: "Please upload your certificate",
		nameLabel: "Certificate name",
		namePlaceholder: "Certificate name",
		nameMsg: "Please enter the certificate name",
		issuingAuthorityLabel: "Issuing organization",
		issuingAuthorityPlaceholder: "Issuing organization",
		issuingAuthorityMsg: "Please enter the issuing organization",
		levelLabel: "Certificate level",
		levelPlaceholder: "Certificate level",
		levelMsg: "Please enter the certificate level",
		submitBtnText: "Confirm upload",
		submitBtnTextEdit: "Submit",
		uploadFileSize: "Certificate file size cannot exceed 5MB"
	},
	order: {
		pageTitle: "My Orders",
		emptyTips: "No orders to display",
		createTimeLabel: "Order time",
		types: {
			deSchool: "Learning Hub",
			careerGuidance: "Career Coaching",
		}
	},
	pay: {
		free: "Free trial (limited time) ",
		accountPaidText: "Pay now",
  		paidText: "Paid"
	},
	training: {
		myTrainPageTitle: "My Training",
		internalTrainingPagePageTitle: "In-house Program",
		evaluationToolsPageTitle: "Assessment Tool",
		tabList: {
			registered: "Registered",
  			collection: "Collection",
		},
		tabTypeList: {
			deSchool: "Learning Hub",
			careerGuidance: "Career Coaching",
		},
		emptyTips: "No content"
	},
	exchangeProgram: {
		pageTitle: "China-Germany Youth Interns Exchange Programme"
	}
}
