// import router from '@/router';
import {setToken, getToken, removeToken, setUserInfo, removeUserInfo, getUserInfo, setIsLogin, getIsLogin, removeIsLogin} from '@/utils/userToken';

const LOGIN_REDIRECT = "LOGIN_REDIRECT";
export default ({
  state: ()=> ({
    userToken: getToken(),
    userInfo: getUserInfo(),
    isLogin: getIsLogin(),
    loginRedirect: null
  }),
  actions: {
    SET_USER_TOKEN: ({ commit }, token) => {
      if (token && token.length > 0) {
        commit('SET_USER_TOKEN', {token});
        //router.push(router.app._route.query.redirect || '/');
      }
    },
    SET_USER_INFO: ({ commit }, user) => {
      commit('SET_USER_INFO', user);
    },
    SET_IS_LOGIN: ({ commit }, isLogin) => {
      commit('SET_IS_LOGIN', isLogin);
    }
  },
  mutations: {
    SET_USER_TOKEN: (state, { token }) => {
      state.userToken = token
      setToken(token)
    },
    REMOVE_USER_TOKEN (state) {
      state.userToken = '';
      removeToken();
    },
    SET_USER_INFO: (state, user) => {
      state.userInfo = user;
      setUserInfo(user);
    },
    REMOVE_USER_INFO (state) {
      state.userInfo = null;
      removeUserInfo();
    },
    SET_IS_LOGIN(state, isLogin) {
      state.isLogin = isLogin;
      setIsLogin(isLogin);
    },
    REMOVE_IS_LOGIN (state) {
      state.isLogin = false;
      removeIsLogin();
    },
    SET_LOGIN_REDIRECT(state, loginRedirect) {
      state.loginRedirect = loginRedirect;
      sessionStorage.setItem(LOGIN_REDIRECT, loginRedirect);
    }
  }
})
