import {GET, POST, POST_JSON} from "@/api/service";

export default {
  /**
   * 获得职位列表
   * @returns {*}
   * @param data (pageNum | pageSize | hostFlag: 1仅查看热门 0:所有)
   */
  getPositionList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/job/getPositionList?${page}`, {
      hostFlag: 0,
      type: data.type,
      companyId: data.companyId,
      province: data.province,
      city: data.city,
      positionCategoryDictId: data.positionCategoryDictId,
      salaryUp: data.salaryUp,
      salaryDown: data.salaryDown,
      searchText: data.searchText
    });
  },
  /**
   * 获取职位详情
   * @param params
   * @returns {*}
   */
  getPositionInfo(params) {
    return POST_JSON(`/job/getPositionInfo`, params);
  },
  /**
   * 获取职位详情(无记录浏览)
   * @param params
   * @returns {*}
   */
  getPositionInfoForAPI(params) {
    return POST_JSON(`/job/getPositionInfoForAPI`, params)
  },
  /**
   * 获得求职者用户信息
   * @returns {*}
   */
  getApplicantUserInfo() {
    return GET(`/sys/user/getApplicantUserInfo`)
  },
  /**
   * 处理面试邀请
   * @param data (id:面试记录id | status: 1.接受 2.拒绝)
   * @returns {*}
   */
  handleInterview(data) {
    return POST_JSON(`/job/handleInterview`, data)
  },
  /**
   * 招聘会信息
   * @param data (id-招聘会编号)
   * @returns {*}
   */
  getRecruitmentInfo(data) {
    return POST_JSON(`/job/getRecruitmentInfo`, data)
  },
  /**
   * 招聘会中职位列表
   * @param data
   * id-招聘会编号
   * pageNum-当前页数
   * pageSize-每页数据
   * @returns {*}
   */
  getRecruitmentPositions(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/job/getRecruitmentPositions?${page}`, {
      // id: data.id
      recruitmentId: data.id
    });
  },
  /**
   * 投递岗位申请(投递简历)
   * @param data
   * positionId-职位id
   * resumeId-当前用户的简历id
   * companyId-公司id
   * @returns {*}
   */
  submitApplication(data) {
    return POST_JSON(`/job/submitApplication`, data)
  },
  /**
   * 申请成为导师
   * @param data
   * specialties-特长
   * contributeResources-贡献资源
   * mediaUrl-上传的多张图片;分割
   * @returns {*}
   */
  submitApplyMentor(data) {
    return POST_JSON(`/job/applyMentor`, data)
  },
  /**
   * 获取导师状态
   * @param data
   * @returns {*}
   */
  getMentorStatus(data) {
    return POST_JSON(`/job/getMentorStatus`, data)
  },
  /**
   * 获取简历投递列表（申请记录）
   * @param data
   * state: 1.全部 | 2.未勾选满意的 | 3.满意 | 4.不满意 | 5.满意 和 不满意的
   * pageNum-
   * pageSize-
   * approveStatus: 1.所有 | 2.待审核 | 3.通过 | 4.拒绝的
   * @returns {*}
   */
  getApplications(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/job/getApplications?${page}`, {
      state: data.state,
      approveStatus: data.approveStatus
    });
  },
  /**
   * 获取面试详情
   * id-面试ID
   * @param data
   * @returns {*}
   */
  getInterviewInfo(data) {
    return POST_JSON(`/job/getInterviewInfo`, data)
  },
  /**
   * 对求职者发起面试邀请（求职者先投递了简历，才可以发送邀请）
   * @param data
   * applicantId-申请Id
   * interviewTime-面试时间
   * address-面试地址
   * matters-测试面试注意事项
   * memo-测试面试备注
   * @returns {*}
   */
  companySubmitInterview(data) {
    return POST_JSON(`/job/companySubmitInterview`, data)
  }
}
