export default {
	common: {
		loadingText: '加载中',
		loadFailed: '加载失败',
		cancelBtnText: '取消',
		submitBtnText: '提交',
		sureBtnText: '确定',
		sendBtnText: '发送',
		loginBtnText: "去登录",
		sendSucceededMsg: '发送成功',
		sendEmailMsg: '已发送至邮箱！',
		unlimitedSelect: '不限',
		age: '岁',
		emailOrPhone: '邮箱/手机号',
		emailOrPhonePlaceholder: '请输入邮箱/手机号',
		passwordLabel: '密码',
		passwordPlaceholder: '请输入密码',
		passwordEmptyTip: '请输入密码',
		passwordFormatTips: "密码必须包含数字和字母，且在6~18位之间",
		forgetPassword: '忘记密码',
		confirmPasswordLabel: '确认密码',
		confirmPasswordPlaceholder: '请输入确认密码',
		confirmPasswordTip: '请输入确认密码',
		confirmPasswordFormatTips: "密码必须包含数字和字母，且在6~18位之间",
		passwordDiffer: '两次输入的密码不一致',
		companyEmptyTip: '请输入企业名称',
		companyLengthTip: '企业名称长度过短',
		companySelectEmptyTip:"请选择企业",
		personalCenter: '个人中心',
		moreText: '查看更多',
		contactUs: '联系我们',
		messages: {
			success: '操作成功',
			saveSuccess: "保存成功",
			uploadSuccess: "上传成功",
			deleteSuccess: "删除成功",
			submitSuccess: "提交成功",
			submitFailed: "提交失败",
			modifySuccess: "修改成功"
		},
		messageBoxTitle: "提示",
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		gotItButtonText: '知道了',
		toImproveBtnText: "去完善",
		toAuditBtnText: "去审核",
		imgTip: '仅支持jpg、png',
		videoTip: '仅支持mp4，视频大小不超过100M',
		selectProvinceAndCity: '选择省市',
		selectCountry: '选择国家',
		btnSaveText: '保存',
		emailLabel: '邮箱',
		emailPlaceholder: '请输入邮箱',
		emailEmptyTip: "请输入邮箱",
		emailCodePlaceholder: '邮箱验证码',
		emailCodeEmptyTip: "请输入邮箱验证码",
		emailErrorTip: '邮箱格式不正确',
		phoneLabel: '手机号',
		phonePlaceholder: '请输入手机号',
		phoneEmptyTips: '请输入手机号',
		phoneErrorTip: '手机号格式不正确',
		imgVerificationTip: '请输入右侧验证码',
		imgCodeEmptyTip: '图形验证码不能为空',
		imgCodeSuccess: '验证码发送成功！',
		messageCodeLabel: '验证码',
		messageCode: '获取验证码',
		messageErrorPlaceholder: '请输入验证码',
		messageErrorCode: '请输入验证码',
		emailPhoneEmptyTip: "请输入手机号/邮箱",
		incorrectEmailPhoneFormat: "手机号或邮箱格式不正确",
		modifyBindingBtnText: "修改绑定",
		confirmBindingBtnText: "确认绑定",
		collection: '收藏',
		collectionAlready: '已收藏',
		download: '下载',
		nationwide:"全国",
		all:"全部",
		languageSelect:{
			Chinese:"中文",
			German:"德语",
			English:"英语",
			// Cantonese:"粤语",
			others:"其他",
			unlimited:"不限"
		},
		loginTip:"请先登录",
		uploadingText: "正在上传",
		submittingText: "正在提交",
		emptyText: "暂无数据",
		comingSoonPleaseWait: "即将上线，敬请期待",
		deleteBtnText: "删除",
		editBtnText: "编辑",
		to: "至",
		cropperDialogTitle: "图片裁剪",
		upToNow: "至今",
		pleaseLoginFirst: "请先登录",
		videoPreviewDialogTitle: "视频预览",
		picPreviewDialogTitle: "图片预览",
		userNotExistTips: "用户不存在",
		currentUserNotPasswordTips: "您还没有设置过密码，请设置密码！",
		oldUserSetPasswordTips: "因系统升级，为保证账号安全性，请先重置密码！",
		incorrectPasswordTips: "密码不正确，请重新输入或重置密码！",
		completeResumeTips: "您的简历已超过1年没有更新，建议重新完善简历。",
		oneClickLoginBindingMobileTips: "是否需要使用一键登录绑定当前手机号。",
		systemError: "系统正忙，请稍后重试！",
		changeOne: "换一张",
		selectPlaceholder: "请选择",
		customBtnText: "自定义",
		onlyJobSeekersTips: "该服务目前仅对求职者用户开放"
	},
	picker: {
		proficiencyLang: {
			title: "选择语言技能"
		},
		salary: {
			title: "选择薪资"
		},
		internship: {
			title: "选择全职/实习岗位"
		},
		jobStatus: {
			title: "选择在职状态"
		},
		positionCategory: {
			title: "选择职位",
		},
		yearMonth: {
			title: "选择年月",
			yearText: "年",
			monthText: "月",
			dayText: "日",
			startTimePlaceholder: "开始时间",
			endTimePlaceholder: "结束时间",
			startTimeEmptyTips: "请选择开始时间",
			endTimeEmptyTips: "请选择结束时间",
			validTimeTips: "结束时间不可以小于开始时间"
		},
		business: {
			title: "选择企业",
			selectPlaceholder: "公司",
			emptyTips: "选择企业"
		},
		positionIndustry: {
			title: "选择行业",
			selectPlaceholder: "请选择",
			emptyTips: "请选择"
		},
		careerGuidanceTime: {
			title: "选择时间",
			selectPlaceholder: "请选择",
			emptyTips: "请选择"
		},
		careerGuidanceAddress: {
			title: "选择地点",
			selectPlaceholder: "请选择",
			emptyTips: "请选择"
		},
		careerGuidanceType: {
			title: "选择线上/线下",
			selectPlaceholder: "请选择",
			emptyTips: "请选择"
		}
	},
	uploadMessage: {
		uploadFiles: "上传文件",
		uploading: "上传中",
		uploadSucceeded: "上传成功",
		uploadFailed: "上传失败"
	},
	applyMentorStatusValues: {
		notApplied: "未申请",
		applying: "申请中",
		pass: "通过",
		reject: "拒绝"
	},
	workYearsSelect: {
		select1: '1年以内',
		select1to3: '1-2年',
		select3to5: '3-5年',
		select5to10: '5-10年',
		select10: '10年以上',
	},
	diplomaSelect: {
		highSchool: '高中',
		juniorCollege: '大学专科',
		undergraduate: '大学本科',
		masterDegree: '硕士研究生',
		doctor: '博士研究生',
	},
	jobStatusSelect: {
		unknown: "未知",
		onDutyWithinAMonth: "在职-月内到岗",
		consideringOpportunities: "在职-考虑机会",
		resignation: "离职",
		students: "在校学生，寻找实习机会"
	},
	resumeStatusValues: {
		notSubmitted: "未提交",
		toBeApproved: "待审核",
		published: "已发布",
		reject: "拒绝",
		disable: "禁用"
	},
	positionStatusValues: {
		draft: '草稿',
		beingApproved: '审核中',
		publish: '发布',
		reject: '拒绝',
		disable: '禁用',
		offline: '下架',
		delete: '删除',
		expired: '已过期'
	},
	sexValues: {
		male: '男',
		female: '女',
		other: '其他',
		unknown: '未知'
	},
	privilegeValues: {
		topPosts: "置顶岗位",
		downloadResume: "下载简历",
		popupTheScreenOnTheHomePage: "首页弹屏",
		publishTheCarouselMap: "发布轮播图",
		logoWallExposed: "首页合作伙伴Logo展示",
		popularCompanyDisplay: "热门公司显示",
		postPosition: "发布职位",
		expiryDateLabel: "有效期截止",
		prohibitUsePrivilege: "抱歉，您暂时不能使用该特权",
		emptyListTips: "暂无专属特权"
	},
	salaryValues: {
		salaryBelow10K: "10K以下",
		salary10To15K: "10-15K",
		salary15To25K: "15-25K",
		salary25To35K: "25-35K",
		salaryAbove35K: "35K以上"
	},
	proficiencyLangValues: {
		notApplied: "尚不具备",
		basicCommunication: "基本交流",
		familiarCommunication: "有效交流",
		proficientCommunication: "熟练交流",
		nativeSpeaker: "母语",
	},
	openClassTypeValues: {
		online: '线上',
		offline: '线下'
	},
	careerCoachingTypeValues: {
		online: '线上',
		offline: '线下'
	},
	openClassLanguageValues: {
		CN: '中文',
		EN: '英语',
		GER: '德语',
		CNtoEN: '中&英',
		CNtoGER: '中&德'
	},
	openClassTimeStatusValues: {
		currentAndUpcoming: '当前',
		past: "已结束"
	},
	login: {
		loginText: '登录',
		logoutText: '退出登录',
		otherLoginTitle: '个人用户登录/求职者登录',
		enterpriseLoginTitle:"企业用户登录",
		notLoginTips: "请登录后在进行操作",
		resetPasswordBtnText: '重置密码',
		ruleTip: '请勾选用户协议',
		agreementDesc: {
			part1: "我已阅读 ",
			part2: "条款和条件",
			part3: " 并同意DE平台协议"
		},
		toSetBtnText: "去设置",
		unbindBtnText: "去绑定",
		toResetBtnText: "去重置",
		registerNowBtnText: "立即注册",
		customerRegisterBtnText: "没有账号/去注册",
		exitMessageTips: "确认退出当前账号？"
	},
	termsConditions: {
		pageTitle: "条款和条件"
	},
	privacyPolicy: {
		pageTitle: "隐私政策"
	},
	paging: {
		finishedDesc: "没有更多了"
	},
	home: {
		contactBtnTxt: '联系我们',
		navList: {
			index: '首页',
			jobs: '职业机会',
			recruit: '人才库',
			train: '学习发展',
			profile: '我的'
		},
		loginText: '登录/注册',
		cn: '中文',
		en: 'Eng',
		tagList: {
			apply: '全职岗',
			practice: '实习岗',
			company: '公司名录',
			video: '职位短视频',
			recruit: '人才库',
			news: 'HR快讯',
			club: '德企俱乐部',
			deSchool: '德才学堂',
			openClass: '公开课',
			internalTraining: '内训/项目',
			careerGuidance: '职涯辅导',
			evaluationTools: '测评工具',
			talentForum: "人才论坛"
		},
		positionText: '热门职位',
		newsText: '最新资讯',
		searchPlaceholder: '职位',
		searchBtnTxt: '搜索',
		focusText1: '欢迎关注',
		focusText2: '我们的公众号',
		cooperation: '合作伙伴',
		sponsor: '赞助商',
		resumeText:"求职者投递简历",
		onlineResumeTitle: "编辑简历",
		onlineResumeDesc: "获得更多的曝光",
		onlineResumeBtnTxt: "立即编辑简历",
		applyMentorTitle: "成为职业导师",
		applyMentorDesc: "成为德商会职业导师",
		applyMentorBtnTxt: "立即提交申请",
		viewCompany: '公司名录'
	},
	resume: {
		pageManageTitle: '简历管理',
		searchPlaceholder: '求职意向',
		searchBtnTxt: '搜索',
		manageTag: {
			tag1: '我的收藏',
			tag2: '我收到的简历',
		},
		sendInterviewState: '已发面试邀请',
		listEmptyText: '暂无符合的简历',
		relevantWorkExperienceDesc: '工作经验',
		diplomaPlaceholder: '学历',
		workYearsPlaceholder: '工作年限',
		positionCategoryPlaceholder: '职位',
		diplomaPickerTitle: '选择学历',
		workYearsPickerTitle: '选择工作年限',
		positionCategoryPickerTitle: '选择职位',
		positionTitleLabel: '投递职位',
		intentionLabel: '求职意向',
		workYearsDesc: '工作经验',
		educationLabel: '教育经历',
		workExperienceLabel: '工作经历',
		jobStatusLabel: '在职状态',
		proficiencyLangeLabel: '语言技能',
		jobSkillsLabel: '职业技能',
		resumeDetailsPageTitle: '求职者',
		downloadResumeBtnText: "下载简历",
	},
	sendInterview: {
		pageTitle: '面试邀请',
		pageDesc: '面试邀请发送后将无法修改',
		pageSubTitle: '面试信息',
		sendInterviewBtnTxt: "发送面试邀请",
		companyLabel: '公司',
		positionLabel: '职位名称',
		provinceCityLabel: '省市',
		provinceCityPlaceholder: '选择省市',
		interviewTimeLabel: '面试时间',
		interviewTimePlaceholder: '请选择面试时间',
		addressLabel: '面试地址',
		addressPlaceholder: '请输入面试地址',
		mattersLabel: '注意事项',
		mattersPlaceholder: '请勿填写微信、QQ',
		interviewTimeMsg: '请选择面试时间！',
		addressMsg: '请填写面试地址！',
		mattersMsg: '请填写注意事项！',
		rejectBtnText: "拒绝",
		acceptBtnText: "接受",
		pickerTitle: "选择完整时间",
		rejectedText: "已拒绝",
		acceptedText: "已接受"
	},
	messages: {
		pageTitle: '消息',
		read: "已读",
		tagList: {
			all: '全部',
			unread: '未读',
			resume: '简历',
			interview: '面试',
			system: '系统',
		},
		messageTypeValues: {
			system: '系统',
			resume: '简历',
			interview: '面试',
		},
		noData: "暂无消息"
	},
	register: {
		enterprisePageTitle: '企业注册',
		candidatePageTitle: '求职者注册',
		newBusinessLabel: '新入驻企业',
		radioYes: '是',
		radioNo: '否',
		nameLabel: '企业名称',
		namePlaceholder: '请输入企业名称',
		nameTip: '请输入企业名称',
		selectBusiness: '选择企业',
		registerBtn: '注册'
	},
	resetPassword: {
		pageTitle: '重置密码',
		btnEditText: '修改'
	},
	positionList: {
		salary: '薪资',
		position: '职位',
		isInternship: '全职/实习',
		company: '公司',
		internshipSelect: {
			internship: '实习岗位',
			formalPosition: '全职岗位',
		},
		emptyTip: '暂无符合的职位',
		positionAll: "全部职位",
		interview: '面议',
		careerFairFlag: "职业展"
	},
	positionDetail: {
		pageTitle: "职位详情",
		apply: '立即申请/投递简历',
		title: '职位描述',
		description: '职位信息',
		requirement: '职位要求',
		postMessage1:"投递成功",
		postMessage2:"您还未完善简历或简历未通过审核",
		postMessage3:'简历未提交审核，去提交审核吗？',
		postMessage4:"您已投递过该职位啦~请耐心等待企业联系",
		interview: '面议',
		publishTimeLabel: "发布时间",
		readingVolumeLabel: "阅读量"
	},
	companyList: {
		pageTitle: "公司列表",
		searchPlaceholder: "公司",
		emptyTips: "暂无公司",
		viewDetails: "查看详情"
	},
	companyDetail: {
		pageTitle: "公司详情",
		companyProfileTitle: "公司介绍",
		positionUnderRecruitmentTitle: "在招职位",
		positionUnderRecruitmentEmptyDesc: "暂无在招职位",
		interview: '面议',
		viewAllBtnText: "查看全部",
		stowBtnText: "收起"
	},
	news:{
		pageTitle:"最新资讯",
		backTitle:"新闻列表",
		noData: "暂无资讯"
	},
	myResume: {
		pageTitle: "我的简历",
		userInfoTitle: "个人信息",
		intentionTitle: '求职意向',
		improveUserInfoTitle: "完善个人信息",
		improveMyResumeTitle: "完善我的简历",
		uploadResumeFile: "上传简历附件",
		reUploadResumeFile: "更新简历附件",
		uploaded: "已上传",
		experienceTitle: "工作经历",
		experienceOldTitle: "工作经历（迁移）",
		toAddExperienceEmpty: "去添加工作经历",
		educationTitle: "教育经历",
		educationOldTitle: "教育经历（迁移）",
		toAddEducationEmpty: "去添加教育经历",
		proficiencyLangeTitle: "语言技能",
		skillTitle: "职业技能",
		haveSkillsTitle: "拥有技能",
		toAddSkillEmpty: "去添加职业技能",
		personalSummaryTitle: "职业优势",
		resumeUploadTitle: "简历附件",
		resumeEditBtnText: "编辑简历",
		completeBtnText: "完成",
		workDescLabel: "工作内容",
		clearOldDataTips: "该内容为旧系统数据，删除后无法恢复，请确认是否删除？",
		clearSuccessTips: "清除成功",
		personalSummaryPlaceholder: "请填写职业优势",
		personalSummaryEmpty: "请填写职业优势",
		resumeFileEmptyDesc: "暂无简历附件",
		experienceEmptyDesc: "暂无工作经历",
		educationEmptyDesc: "暂无教育经历",
		proficiencyLangEmptyDesc: "暂无语言技能",
		skillEmptyDesc: "暂无职业技能",
		personalSummaryEmptyDesc: "暂无职业优势",
		uploadFileDesc: {
			part1: '可上传PDF文件，最大不超过5M',
			part2: ''
		},
		uploadFileType: "简历附件仅支持pdf",
		uploadFileSize: "简历文件大小不能超过5MB",
		uploadFileDeleteDesc: "确定要删除简历附件吗？",
		proficiencyLangOtherPlaceholder: "请输入您的其他语言能力",
		skillNamePlaceholder: "请输入自定义技能",
		skillMaxNumberTips: {
			part1: '最多添加',
			part2: '个职业技能'
		},
		skillNameEmptyTips: "请输入自定义技能",
		skillNameFontNumberTips: "自定义技能不超过10个字",
		skillSelectEmptyTips: "请选择技能",
		positionCategoryPageTitle: "求职意向",
		positionCategoryPlaceholder: "请选择求职意向",
		positionCategoryEmptyTips: "请选择求职意向",
		positionCategoryPlaceholderPicker: "请输入职位",
		positionCategoryMaxNumberTips: {
			part1: '最多只可添加',
			part2: '个求职意向'
		},
		selectedTips: "已选",
		education: {
			addPageTitle: "添加教育经历",
			editPageTitle: "编辑教育经历",
			deleteDataTips: "删除此教育经历，删除后无法恢复，请确认是否删除？",
			nameLabel: "学校名称",
			namePlaceholder: "请输入学校名称",
			nameEmptyTips:"请输入学校名称",
			diplomaLabel: "学历",
			diplomaPlaceholder: "请选择学历",
			diplomaEmptyTips: "请选择学历",
			diplomaPickerTitle: "选择学历",
			majorLabel: "专业",
			majorPlaceholder: "请输入专业",
			majorEmptyTips: "请输入专业",
			timeLabel: "在校时间",
			timeEmptyTips: "请选择在校时间"
		},
		experience: {
			addPageTitle: "添加工作经历",
			editPageTitle: "编辑工作经历",
			deleteDataTips: "删除此工作经历，删除后无法恢复，请确认是否删除？",
			companyNameLabel: "公司名称",
			companyNamePlaceholder: "请输入公司名称",
			companyNameEmptyTips:"请输入公司名称",
			companyLengthTip: "公司名称长度过短",
			positionNameLabel: "职位",
			positionNamePlaceholder: "请输入职位",
			positionNameEmptyTips:"请输入职位",
			performanceLabel: "工作内容",
			performancePlaceholder: "请输入内容",
			performanceEmptyTips:"请输入内容",
			timeLabel: "在职时间",
			timeEmptyTips: "请选择在职时间"
		},
		userInfo: {
			avatarBtnText: "上传头像",
			userEmailLabel: "邮箱",
			modifyEmailBtnTxt: "修改邮箱",
			improveEmailBtnTxt: "完善邮箱",
			improveEmailTitle: "完善邮箱",
			modifyEmailTitle: "修改邮箱",
			lastNameLabel: "姓",
			lastNamePlaceholder: "请输入姓",
			lastNameEmptyTips: "请输入姓",
			firstNameLabel: "名",
			firstNamePlaceholder: "请输入名",
			firstNameEmptyTips: "请输入名",
			sexLabel: "性别",
			sexPlaceholder: "请选择性别",
			sexEmptyTips: "请选择性别",
			birthdayLabel: "出生年月",
			birthdayPlaceholder: "请选择出生年月",
			birthdayEmptyTips: "请选择出生年月",
			birthdayPickerTitle: "选择出生年月",
			countryLabel: "国籍",
			countryPlaceholder: "请选择国籍",
			countryEmptyTips: "请选择国籍",
			countryPickerTitle: "选择国籍",
			areaLabel: "省市",
			areaPlaceholder: "请选择省市",
			areaEmptyTips: "请选择省市",
			areaPickerTitle: "选择省市",
			addressLabel: "地址",
			addressPlaceholder: "请输入地址",
			addressEmptyTips: "请输入地址",
			nativeLanguageLabel: "母语",
			nativeLanguagePlaceholder: "请选择母语",
			nativeLanguageEmptyTips: "请选择母语",
			nativeLanguagePickerTitle: "选择母语",
			workYearsLabel: "工作年数",
			workYearsPlaceholder: "请选择工作年数",
			workYearsEmptyTips: "请选择工作年数",
			workYearsPickerTitle: "选择工作年数",
			workAreaLabel: "偏好工作地点",
			workAreaPlaceholder: "请选择偏好工作地点",
			workAreaEmptyTips: "请选择偏好工作地点",
			workAreaPickerTitle: "选择偏好工作地点",
			jobStatusLabel: "在职状态",
			jobStatusPlaceholder: "请选择在职状态",
			jobStatusEmptyTips: "请选择在职状态",
			emailLabel: "联系邮箱",
			emailPlaceholder: "请输入邮箱",
			emailEmptyTips: "请输入邮箱",
			verificationCodeTitle: "安全验证",
			verificationCodeLabel: "验证码",
			verificationCodePlaceholder: "请输入邮件中的验证码",
			verificationCodeEmptyTips: "请输入邮件中的验证码",
			resumeFileEmptyDesc: "暂无简历附件",
			experienceEmptyDesc: "暂无工作经历",
			educationEmptyDesc: "暂无教育经历",
			proficiencyLangEmptyDesc: "暂无语言技能",
			skillEmptyDesc: "暂无职业技能",
			personalSummaryEmptyDesc: "暂无职业优势"
		},
		proficiencyLange: {
			pageTitle: "语言技能",
			title: "语言技能",
			langeDesc: "您在商业环境中的语言能力如何？",
			chineseLabel: "中文/普通话",
			englishLabel: "英文",
			germanLabel: "德语",
			othersLabel: "其他",
			othersPleaseSpecifyLabel: "其他（请注明）",
			pleaseSelect: "请选择"
		}
	},
	applyMentor: {
		pageTitle: "申请职业导师",
		underReviewTips: "您的导师申请正在审核中",
		approvedTips: "您已经是职业导师",
		specialtiesLabel: "擅长的领域",
		specialtiesPlaceholder: "请输入内容",
		specialtiesEmptyTips: "请输入擅长的领域",
		contributeResourcesLabel: "愿意贡献的资源",
		contributeResourcesPlaceholder: "请输入内容",
		contributeResourcesEmptyTips: "请输入愿意贡献的资源",
		mediaLabel: "正装照",
		mediaEmptyTips: "请上正装照",
		activityPhotoLabel: "照片",
		activityPhotoEmptyTips: "请上传照片",
		videoLabel: "视频",
		videoEmptyTips: "请上传视频",
		submitSuccess: "提交成功，请等待审核！",
		upload: {
			onlyOneVideoFileTips: "只能上传1个视频文件",
			uploadFileSizeTips: "上传视频不能超过100MB"
		},
		submitApplicationBtnTxt: "提交申请",
		mediaUploadDesc: {
			part1: "大小不超过3M（一张）"
		},
		activityPhotoUploadDesc: {
			part1: "可上传参与活动的照片，最多可上传",
			part2: "张，每张大小不超过3M"
		},
		videoUploadDesc: {
			part1: "可上传参与过的活动视频剪辑；大小不超过100M"
		},
		tagTitle: "职业导师"
	},
	productList: {
		pageTitle: "付费项目列表",
		detailsBtnText: "详情"
	},
	recruit: {
		pageTitle: "招聘会",
		participatingEnterprisesTitle: "参与企业",
		moreInformationTitle: "详情介绍",
		positionInformationTitle: "职位列表",
		positionNoData: "暂无职位"
	},
	myFavorites: {
		pageTitle: "我的收藏",
		noData: "暂无收藏内容"
	},
	myApplicationRecord: {
		pageTitle: "申请记录",
		noData: "暂无申请记录",
		applicationTime: "申请时间",
		interviewInvitationReceived: "已收到面试邀请",
		posted: "已投递"
	},
	profileIndex: {
		messageTitle: '我的消息',
		unread: '未读',
		orderTitle: '我的订单',
		unpaid: '未付款'
	},
	profileBusiness: {
		businessDesc: "体验完整功能请登录PC端平台",
		toolsDesc: {
			part1: "职位管理",
			part2: "简历管理",
			part3: "其他企业专属服务"
		}
	},
	profileConsumer: {
		updateImproveBtnText: "更新/完善信息",
		recordTags: {
			applicationRecord: "申请记录",
			collectionRecord: "我的收藏",
			trainingRecord: "我的培训",
			myCertificate: "我的证书"
		}
	},
	contactUs: {
		pageTitle: "联系我们"
	},
	employerBranding: {
		pageTitle: '雇主品牌推广'
	},
	openClass: {
		title: '公开课',
		timeStatusPickerTitle: '时间',
		languagePickerTitle: '语言',
		typePickerTitle: '线上/线下',
		emptyTips: "暂无公开课",
		free: "免费",
		vip: "会员",
		nonVip: "非会员",
		searchPlaceholder: "请输入关键词"
	},
	monthView: {
		Jan: "01月",
		Feb: "02月",
		Mar: "03月",
		Apr: "04月",
		May: "05月",
		Jun: "06月",
		Jul: "07月",
		Aug: "08月",
		Sep: "09月",
		Oct: "10月",
		Nov: "11月",
		Dec: "12月"
	},
	careerGuidance: {
		title: '职涯辅导',
		detailsTitle: '职涯辅导预约',
		emptyTips: "暂无职涯辅导",
		makeAnAppointmentBtnText: "立即预约",
		consultingFeeLabel: "咨询费用",
		mentorContentTitle: "导师简介",
		viewAllBtnText: "查看全部"
	},
	careerGuidanceForm: {
		nameLabel: '姓名',
		contactInfoLabel: '联系方式',
		industryLabel: '行业',
		occupationLabel: '职业背景',
		typeLabel: '线上/线下',
		timeLabel: '时间',
		addressLabel: '地点',
		namePlaceholder: '请输入',
		contactInfoPlaceholder: '请填写手机号',
		industryPlaceholder: '请选择',
		occupationPlaceholder: '请填写详细背景',
		typePlaceholder: '请选择',
		timePlaceholder: "请选择",
		addressPlaceholder: '请选择',
		nameMsg: '请填写姓名',
		contactInfoMsg: '请填写手机号',
		industryMsg: '请选择行业',
		typeMsg: '请选择线上/线下',
		timeMsg: "请选择时间",
		addressMsg: '请选择地址',
		repeatAppointmentTips: "您已预约过该导师，是否确认再次预约？"
	},
	certificate: {
		pageTitle: "我的证书",
		pageTitleEdit: "修改证书",
		pageTitleAdd: "上传证书",
		addBtnText: "上传新证书",
		emptyTips: "暂无证书",
		deleteTips: "确认要删除该证书吗？"
	},
	certificateForm: {
		uploadAttachmentLabel: "上传证书附件",
		uploadAttachmentMsg: "请上传证书附件",
		nameLabel: "证书名称",
		namePlaceholder: "请填写证书名称",
		nameMsg: "请填写证书名称",
		issuingAuthorityLabel: "发证机关",
		issuingAuthorityPlaceholder: "请填写发证机关",
		issuingAuthorityMsg: "请填写发证机关",
		levelLabel: "证书等级",
		levelPlaceholder: "请填写证书等级",
		levelMsg: "请填写证书等级",
		submitBtnText: "确认上传",
		submitBtnTextEdit: "提交",
		uploadFileSize: "证书附件大小不能超过5MB"
	},
	order: {
		pageTitle: "我的订单",
		emptyTips: "暂无订单",
		createTimeLabel: "下单时间",
		types: {
			deSchool: '德才学堂',
			careerGuidance: '职涯辅导'
		}
	},
	pay: {
		free: "限时体验",
		accountPaidText: "去付款",
		paidText: "已付款"
	},
	training: {
		myTrainPageTitle: "我的培训",
		internalTrainingPagePageTitle: "内训/项目",
		evaluationToolsPageTitle: "测评工具",
		tabList: {
			registered: "已报名",
			collection: '收藏'
		},
		tabTypeList: {
			deSchool: '德才学堂',
			careerGuidance: '职涯辅导'
		},
		emptyTips: "暂无内容"
	},
	exchangeProgram: {
		pageTitle: "中德青年实习交流计划"
	}
}
