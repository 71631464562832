<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="includeList">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>
<script>
import { isEmpty, loadJS } from "@/utils/utils";
import { reactive, watch, toRefs } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
export default {
  setup(){
    const state = reactive({
      includeList: ["PositionList", "CompanyList"],
      language: ""
    })
    const route = useRoute()
    // const router = useRouter()
    // watch(() => route,(newVal,oldVal)=>{
      // if(newVal.meta.keepAlive && state.includeList.indexOf(newVal.name) === -1){
      //   state.includeList.push(newVal.name);
      // }
    // },{deep:true}) // 开启深度监听
    return{
      ...toRefs(state)
    }
  },
  data() {
    return {
      language: ''
    }
  },
  created() {
    this.getLanguage();
    loadJS('https://resources.de-talents.com/ahk/Static/Js/regions.js', false).then(()=> {
      this.getProvinceCityJson();
    });
    loadJS('https://resources.de-talents.com/ahk/Static/Js/dict_data.js', false).then(()=> {
      this.getDictList();
    });
  },
  methods: {
    getLanguage(){
      let language = null
      if (isEmpty(localStorage.getItem('language'))) {
        language = 'zh'
      } else {
        language = localStorage.getItem('language')
      }
      this.language = language
    },
    getDictList() {
      let resumeSkillKeyList = [];
      let resumeSkillValueList = [];
      let resumePositionCategoryKeyList = [];
      let resumePositionCategoryValueList = [];
      DICT_DATA.forEach((row)=> {
        if(row.dictType === "resume_skill") {
          row.dicts.forEach((dict)=> {
            resumeSkillKeyList.push(dict.dictCode);
            resumeSkillValueList.push(this.language === 'zh' ? dict.dictLabel : dict.dictLabelEn);
          });
        } else if(row.dictType === "resume_position_category") {
          row.dicts.forEach((dict)=> {
            resumePositionCategoryKeyList.push(dict.dictCode);
            resumePositionCategoryValueList.push(this.language === 'zh' ? dict.dictLabel : dict.dictLabelEn);
          });
        }
      });
      let resumeSkillValues = this.mapArrays(resumeSkillKeyList, resumeSkillValueList);
      this.$store.commit('SET_RESUME_SKILL_VALUES', resumeSkillValues);

      let resumePositionCategoryValues = this.mapArrays(resumePositionCategoryKeyList, resumePositionCategoryValueList);
      this.$store.commit('SET_RESUME_POSITION_CATEGORY_VALUES', resumePositionCategoryValues);
    },
    //格式化values
    mapArrays (keyArray = [], valueArray = []) {
      return keyArray.reduce((acc, elem, index) => {
        acc[elem] = valueArray[index];
        return acc;
      }, {});
    },
    getProvinceCityJson() {
      let PROVINCE_CITY_LIST = '';
      let PROVINCE_CITY_LIST_ALL = '';
      if((isEmpty(PROVINCE_CITY_LIST) || isEmpty(PROVINCE_CITY_LIST_ALL))) {
        let list = [];
        REGIONS_DATA.forEach((provinceRow)=> {
          let province = {
            id: provinceRow.id,
            value: provinceRow.ext_id,
            label: this.handleLabel(provinceRow),
            children: provinceRow.childs.map((cityRow)=> {
              return {
                id: cityRow.id,
                value: provinceRow.name === cityRow.name ? provinceRow.ext_id : cityRow.ext_id,
                label: provinceRow.name === cityRow.name ? this.handleLabel(provinceRow) : this.handleLabel(cityRow),
                parentId: provinceRow.id
              }
            })
          }
          list.push(province);
        });
        let listAll = this.formatRowsAll(JSON.parse(JSON.stringify(list)));
        //全国
        // listAll.unshift({
        //   id: 0,
        //   value: $t('common.nationwide'),
        //   label: $t('common.nationwide'),
        //   children: [{
        //     id: 1,
        //     value: $t('common.all'),
        //     label: $t('common.all'),
        //     parentId: 0
        //   }]
        // });
        this.$store.commit('SET_PROVINCE_CITY_LIST', list);
        this.$store.commit('SET_PROVINCE_CITY_LIST_ALL', listAll);
      } else {
        this.$store.commit('SET_PROVINCE_CITY_LIST', JSON.parse(PROVINCE_CITY_LIST));
        this.$store.commit('SET_PROVINCE_CITY_LIST_ALL', JSON.parse(PROVINCE_CITY_LIST_ALL));
      }
    },
    handleLabel(item){
      return this.language === 'zh' ? item.name : item.pinyin
    },
    formatRowsAll(rows) {
      rows && rows.forEach((row)=> {
        if(row.children.length>1) {
          row.children.unshift({
            id: row.id,
            value: row.value,
            label: $t('common.all'),
            parentId: row.id
          });
        }
      });
      return rows;
    }
  }
}
</script>
<style lang="scss">
@import "styles/index";
#app {
  font-size: 28px;
  font-family: Avenir, Helvetica, Arial, sans-serif, Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-color-2);
}
</style>
