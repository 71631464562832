import {GET, POST, POST_JSON} from "@/api/service";

export default {
  /**
   * 培训首页
   * @param data deviceCode-端分类: 1.MB | 2.PC
   * @returns {*}
   */
  getTrainingHomeList(data) {
    return POST_JSON(`/training/homePage?deviceCode=${data.deviceCode}`);
  }
}
