import {GET, POST, POST_JSON} from "@/api/service";

export default {
  /**
   * 获得我的未读消息数量
   * @returns {*}
   */
  getMyMsgCount() {
    return POST(`/sys/user/getMyMsgCount`);
  },
  /**
   * 获得我的消息列表
   * @param data
   * type: 0.全部部 | 1.系统 | 2.简历 | 3.面试
   * pageNum
   * pageSize
   * @returns {*}
   */
  getMyMessageList(data) {
    let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return POST_JSON(`/sys/user/myMessageList?${page}`, {
      type: data.type
    });
  }
}
