<template>
  <div>
    <app-main />
  </div>
</template>
<script>
import { AppMain } from './components';
export default {
  name: 'Layout',
  components: {
    AppMain
  }
};
</script>
