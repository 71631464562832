import { createI18n } from 'vue-i18n/index'
import { Locale } from 'vant';
import zhLocale from './zh'
import enLocale from './en'
import { isEmpty } from '@/utils/utils'
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';

const messages = {
	zh: {
		...zhLocale
	},
	en: {
		...enLocale
	}
}

export function getLanguage() {
	let language = null
	if (isEmpty(localStorage.getItem('language'))) {
		language = 'zh';
	} else {
		language = localStorage.getItem('language');
	}
	let chooseLanguage = language;
	if(chooseLanguage === 'zh') {
		Locale.use('zh-CN', zhCN);
	} else {
		Locale.use('en-US', enUS);
	}
	localStorage.setItem('language', chooseLanguage);
	if (chooseLanguage) return chooseLanguage;
}

const i18n = createI18n({
	locale: getLanguage(),
	messages,
})

export default i18n
